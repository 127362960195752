import { 
    forwardRef, 
    useContext, 
    useEffect, 
    useImperativeHandle, 
    useRef, 
    useState 
} from "react";

import { 
    CompanyContext, 
    LoadingContext, 
    UserContext 
} from "../../../context/handleContext";

import { 
    Box, 
    Button, 
    ButtonGroup, 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    IconButton, 
    InputLabel, 
    List, 
    ListItem, 
    MenuItem, 
    Modal, 
    Paper, 
    Select, 
    TextField, 
    Tooltip, 
    Typography, 
    useTheme 
} from "@mui/material";

import Table from '../../table/virtualized'

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AvTimerIcon from '@mui/icons-material/AvTimer';

import { toast } from "react-toastify";
import { api } from "../../../utils/defaults";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { float, integer } from "../../../utils/input";
import WeekdayScheduler from "../../../utils/weekdayscheduler";

const billingType = {
    'fixed': 'Fixo',
    'action': 'Por Ação',
    'day': 'Por Dia',
    'hour': 'Por Hora',
    'minute': 'Por Minuto'
}

const AssetContractFormCompany = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user] = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    const [esp, setEsp] = useState([])
    const [cst, setCst] = useState([])
    const [estq, setEstq] = useState([])
    const [area, setArea] = useState([])

    const theme = useTheme()
    const weekdayref = useRef()

    useImperativeHandle(ref, () => ({
        open: data => {
            setOpen(true)
            setState(data)
            setCst(data.lista_custos || [])
            setEstq(data.area_venda || [])
        },
        close: () => setOpen(false)
    }))

    const handleSave = async () => {
        console.log({
            ...state,
            estoque: state.controla_estoque_area_venda ? null : state.estoque,
            lista_custos: cst,
            area_venda: estq.map(e => ({...e, quantidade_disponivel: e.new ? e.quantidade : e.quantidade_disponivel}))
        })
        // setLoading(true)
        // try {
        //     await axios.put(`${api}/api/manage/marketing/trade/assets/company/${state.idtrademktativo}/${state.idloja}`, {
        //         ...state,
        //         estoque: state.controla_estoque_area_venda ? null : state.estoque,
        //         lista_custos: cst,
        //         estoque_area_venda: estq.map(e => ({...e, quantidade_disponivel: e.new ? e.quantidade : e.quantidade_disponivel}))
        //     },{
        //         headers: {
        //             Authorization: user.token
        //         }
        //     })
        //     toast.success('Salvo')
        //     if ( typeof props.onUpdate === 'function' ) props.onUpdate()
        // } catch (error) {
        //     toast.error(error?.response?.data?.message || error?.response?.data || "Erro ao salvar ativo");
        // }
        // setLoading(false)
    };

    const handleAddCst = () => setCst([...cst,{ name: "", value: 0 }])

    const handleRemoveCst = (index) => {
        const updated = cst.filter((_, i) => i !== index);
        setCst(updated);
    };

    const handleAddEstq = () => setEstq([...estq,{ idareavenda: null, quantidade: 0, quantidade_disponivel: 0, new: true }])

    const handleRemoveEstq = (index) => {
        const updated = estq.filter((_, i) => i !== index);
        setEstq(updated);
    };

    useEffect(() => setArea(props.area),[props.area])
    useEffect(() => {
        if (props.open) return 
        setEstq([])
        setCst([])
        setState({})
    },[props.open])

    return (
        <Modal 
        open={open} 
        onClose={() => setOpen(false)}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                width: "90%",
                maxWidth: 800,
                margin: "auto",
                // marginTop: "5%",
                backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
                color:'text.primary',
                maxHeight:'90vh',
                overflowY:"auto"
            }}
            >
                <WeekdayScheduler 
                ref={weekdayref}
                onChange={periodo => setState({...state, periodo})}
                />
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%'
                }}
                >
                    <div
                    style={{ display:'flex', flexDirection:"column"}}
                    >
                        <Typography
                        
                        >
                            Personalizar "{state.nome}"
                        </Typography>
                        <Typography
                        sx={{
                            color:'text.secondary',
                            fontSize:'14px'
                        }}
                        >
                            Loja "{state.nomeloja}"
                        </Typography>
                    </div>

                    <div>
                        <Button
                        // color='success'
                        variant='outlined'
                        size='small'
                        sx={{marginRight:'10px'}}
                        startIcon={<AvTimerIcon />}
                        onClick={() => {
                            weekdayref.current.loadSchedule(state.periodo || {})
                            weekdayref.current.openModal()
                        }}
                        >período</Button>

                        <Button
                        color='success'
                        variant='outlined'
                        size='small'
                        sx={{marginRight:'10px'}}
                        startIcon={<SaveAltIcon />}
                        onClick={handleSave}
                        >salvar</Button>

                        <Button
                        color='error'
                        variant='outlined'
                        size='small'
                        startIcon={<CloseIcon />}
                        onClick={() => setOpen(false)}
                        >fechar</Button>
                    </div>
                </div>
                {!state.centraliza_estoque || state.controla_estoque_area_venda ? 
                <>
                    <div
                    style={{
                        display:"flex",
                        alignItems:"center",
                        marginTop:'15px',
                        marginBottom:'10px'
                        // justifyContent:"center"
                    }}
                    >
                        <Typography variant="h6">
                            {state.controla_estoque_area_venda ? 'Quantidade por área de venda' : 'Quantidade'}
                        </Typography>

                        {state.controla_estoque_area_venda ? 
                        <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={handleAddEstq}
                        size='small'
                        sx={{marginLeft:'10px'}}
                        disabled={!area.filter(e => (
                            !(estq.map(i => i.idareavenda)).includes(e.all_category_id)
                        )).length ||
                        estq.length >= area.length}
                        >
                        Adicionar
                        </Button> : 
                        <TextField 
                        value={state.quantidade}
                        onChange={({ target }) => setState({ ...state, quantidade: integer(target.value) })}
                        size="small"
                        sx={{width:'150px',marginLeft:'10px'}}
                        label='Quantidade'
                        />}
                    </div>
                    {state.controla_estoque_area_venda && estq.length ? 
                    <Paper elevation={3} sx={{padding:'15px 10px 10px 15px'}}>
                        {estq.map((item, index) => (
                            <ListItem 
                            sx={{
                                p:0,
                                paddingBottom:'8px'
                            }}
                            key={index}>
                                <FormControl
                                sx={{ width: "50%", marginRight: 2 }}
                                size='small'
                                >
                                    <InputLabel>Área de Venda</InputLabel>
                                    <Select
                                    value={item.idareavenda}
                                    label="Age"
                                    onChange={(e) => {
                                        const updated = [...estq];
                                        updated[index].idareavenda = e.target.value;
                                        setEstq(updated);
                                    }}
                                    >
                                        {area.filter(e => (
                                            !(estq.map(i => i.idareavenda)).includes(e.all_category_id) ||
                                            e.all_category_id === item.idareavenda
                                        )).map(e => (<MenuItem value={e.all_category_id}>{e.name}</MenuItem>))}
                                    </Select>
                                </FormControl>

                                <TextField
                                label="Quantidade"
                                value={item.quantidade}
                                onChange={(e) => {
                                    const updated = [...estq];
                                    updated[index].quantidade = integer(e.target.value);
                                    setEstq(updated);
                                }}
                                sx={{ width: "40%", marginRight: 2 }}
                                size="small"
                                />
                                <IconButton onClick={() => handleRemoveEstq(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </Paper> : 
                    <></>}
                </> : 
                <></>}

                <Typography variant="h6" sx={{ marginTop: 4 }}>
                    Custo e Venda
                </Typography>

                <div
                style={{
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"center",
                    marginTop:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        alignItems:'center'
                    }}
                    >
                        <TextField 
                        value={state.custo_fixo}
                        onChange={({ target }) => setState({ ...state, custo_fixo: float(target.value) })}
                        size="small"
                        sx={{width:'150px',marginRight:'10px'}}
                        label='Custo Simples'
                        disabled={cst?.length}
                        />

                        <Tooltip title='Periodicidade que irá contabilizar o custo'>
                            <ButtonGroup
                            size='small'
                            >
                                <Button onClick={() => setState({ ...state, tipo_custo: 'fixed'})} variant={state.tipo_custo === 'fixed' ? 'contained' : 'outlined'}>fixo</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'action'})} variant={state.tipo_custo === 'action' ? 'contained' : 'outlined'}>por ação</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'day'})} variant={state.tipo_custo === 'day' ? 'contained' : 'outlined'}>por dia</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'hour'})} variant={state.tipo_custo === 'hour' ? 'contained' : 'outlined'}>por hora</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'minute'})} variant={state.tipo_custo === 'minute' ? 'contained' : 'outlined'}>por minuto</Button>
                            </ButtonGroup>
                        </Tooltip>
                    </div>

                    <Tooltip title='Use para detalhar o custo montando uma composição'>
                        <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={handleAddCst}
                        size='small'
                        >
                        Custo Composto
                        </Button>
                    </Tooltip>
                </div>

                <List
                sx={{
                    m:0,
                    p:0,
                    marginTop:'15px'
                }}
                >
                {cst.map((item, index) => (
                    <ListItem 
                    sx={{
                        p:0,
                        paddingBottom:'8px'
                    }}
                    key={index}>
                        <TextField
                        label="Nome do custo"
                        value={item.name}
                        onChange={(e) => {
                            const updated = [...cst];
                            updated[index].name = e.target.value;
                            setCst(updated);
                        }}
                        sx={{ width: "50%", marginRight: 2 }}
                        size="small"
                        >
                        </TextField>
                        <TextField
                        label="Valor"
                        value={item.value}
                        onChange={(e) => {
                            const updated = [...cst];
                            updated[index].value = float(e.target.value);
                            setCst(updated);
                        }}
                        sx={{ width: "40%", marginRight: 2 }}
                        size="small"
                        />
                        <IconButton onClick={() => handleRemoveCst(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
                </List>

                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    marginTop:'15px'
                }}
                >
                    <TextField 
                    value={state.valor}
                    onChange={({ target }) => setState({ ...state, valor: float(target.value) })}
                    size="small"
                    sx={{ width:'150px', marginRight:'10px' }}
                    label='Valor'
                    />

                    <Tooltip
                    title='Periodicidade que irá contabilizar o valor'
                    >
                        <ButtonGroup
                        size='small'
                        >
                            <Button onClick={() => setState({ ...state, tipo_valor: 'action'})} variant={state.tipo_valor === 'action' ? 'contained' : 'outlined'}>por ação</Button>
                            <Button onClick={() => setState({ ...state, tipo_valor: 'day'})} variant={state.tipo_valor === 'day' ? 'contained' : 'outlined'}>por dia</Button>
                            <Button onClick={() => setState({ ...state, tipo_valor: 'hour'})} variant={state.tipo_valor === 'hour' ? 'contained' : 'outlined'}>por hora</Button>
                            <Button onClick={() => setState({ ...state, tipo_valor: 'minute'})} variant={state.tipo_valor === 'minute' ? 'contained' : 'outlined'}>por minuto</Button>
                        </ButtonGroup>
                    </Tooltip>
                </div>
            </Box>
        </Modal>   
    )
})

export default AssetContractFormCompany