import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { UserContext } from '../../../context/handleContext';
import axios from 'axios';
import { api } from '../../../utils/defaults';
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function AssetContractForm({ openAsset, openSettings, ..._ }) {
    const [user] = React.useContext(UserContext)
    const [expanded, setExpanded] = React.useState(false);
    const [company, setCompany] = React.useState([])
    const [selected, setSelected] = React.useState([])

    const updateCompany = async () => {
        try {
            const response = await axios.get(`${api}/api/manage/marketing/trade/assets/company/${_.idtrademktativo}`,{
                headers: {
                    Authorization: user.token
                }
            })
            
            setCompany(response.data.map(e => ({
                ...e, 
                area_venda: e.estoque_area_venda || _.estoque_area_venda,
                custo: e.custo || _.custo,
                valor: e.valor || _.valor,
                tipo_custo: e.tipo_custo || _.tipo_custo,
                tipo_valor: e.tipo_valor || _.tipo_valor,
                lista_custos: e.lista_custos || _.lista_custos
            })))
        } catch (err) {
        }
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    React.useEffect(() => {
        updateCompany()
    },[_.idtrademktativo])

    // console.log(company)

    return (
        <Card sx={{ 
            maxWidth: 345, 
            // height:'100%'
        }}>
        <CardHeader
            // avatar={
            // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            //     R
            // </Avatar>
            // }
            action={
            <IconButton 
            onClick={openAsset}
            aria-label="settings">
                <VisibilityOutlinedIcon />
            </IconButton>
            }
            title={_.nome}
            subheader={_.nometipoativo}
        />
        {/* <CardMedia
            component="img"
            height="194"
            image="/static/images/cards/paella.jpg"
            alt="Paella dish"
        /> */}
        <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {_.descricao || 'Sem descrição'}
            </Typography>
        </CardContent>
        <CardActions disableSpacing>
            {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
            <ShareIcon />
            </IconButton> */}
            <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {/* Selecionar todos / Remover todos */}

                    {company.map((e) => {
                        const checked = selected.map(({ idloja }) => (idloja)).includes(e.idloja)

                        return (
                        <ListItem
                            // key={value}
                            secondaryAction={
                            <IconButton 
                            onClick={() => openSettings(e)}
                            disabled={!checked}
                            edge="end" aria-label="settings">
                                <SettingsIcon />
                            </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton 
                            role={undefined} 
                            onClick={() => {
                                if ( checked ) setSelected(old => old.filter(({ idloja }) => idloja !== e.idloja))
                                else setSelected(old => [...old, e])
                            }} 
                            dense>
                            <ListItemIcon>
                                <Checkbox
                                edge="start"
                                checked={checked}
                                // tabIndex={-1}
                                disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={e.nomeloja} />
                            </ListItemButton>
                        </ListItem>
                        );
                    })}
                    </List>
            </CardContent>
        </Collapse>
        </Card>
    );
}
