import { 
    Box, 
    Button, 
    ButtonBase, 
    Divider, 
    IconButton, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    Modal, 
    Popover, 
    TextField, 
    Typography 
} from "@mui/material"

import { useEffect, useState } from "react"

import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import CastIcon from '@mui/icons-material/Cast';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv'; //conectar
import TvIcon from '@mui/icons-material/Tv';
import TvOffIcon from '@mui/icons-material/TvOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import WifiFindIcon from '@mui/icons-material/WifiFind';

import ResetTvIcon from '@mui/icons-material/ResetTv';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import { useTheme } from '@mui/material/styles';
import Circle from 'react-circle';
import { useContext } from "react";
import { LoadingContext, SizeContext, SocketContext, UserContext } from "../../../context/handleContext";
import axios from "axios";
import { api } from "../../../utils/defaults";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Chip (props) {
    const [size, setSize] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [expanded, setExpanded] = useState(props.expanded)
    const [state, setState] = useState(props.data)
    const [connectionAnchor, setConnectionAnchor] = useState(null)
    const [appAnchor, setAppAnchor] = useState(null)
    const [cecState, setCecState] = useState({ open: false })
    const [password, setPassword] = useState({ open: false })

    const socket = useContext(SocketContext)
    const theme = useTheme()

    useEffect(() => {
        setState(props.data)
        socket.on(`price_manager_monitor_${props.data?.idtabelapreco}`, e => setState({...state, ...e}))

        return () => {
            socket.off(`price_manager_monitor_${props.data?.idtabelapreco}`)
        }
    }, [props.data])

    useEffect(() => setExpanded(props.expanded), [props.expanded])

    const power = ( mode ) => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/action/television/state/${state.idtabelapreco}`,{
            device_id: cecState.address,
            state: mode
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => toast.success('Enviado'))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const active = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/action/television/active/${state.idtabelapreco}`,{},{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => toast.success('Enviado'))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const volume = (state) => {
        // setLoading(true)
        // axios
        // .post(`${api}/hdmicec/volume/${cecState.address}/${state}`,{},{
        //     headers: {
        //         Authorization: user.token
        //     }
        // })
        // .then(({data}) => toast.success('Enviado'))
        // .catch(err => toast.error(err?.response?.data))
        // .finally(() => setLoading(false))
    }

    const submitPassword = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/action/password/${state.idtabelapreco}`,{
            password: password.password || null
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => toast.success('Enviado'))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    return (
        <div
        style={{
            margin:'10px',
            backgroundColor: theme.palette.mode === 'dark' ? '#171717' : '#fff',
            boxShadow: `0px 0px 10px rgba(0,0,0,${theme.palette.mode === 'dark' ? 1 : 0.5})`,
            padding: '10px',
            width:'inherit',
            borderRadius:'5px',
            display:'flex',
            minWidth: expanded ? '150px' : '120px',
            width: size.width <= 500 && expanded ? '100%' : 'auto',
            flexWrap: ''
        }}
        >
            <Modal
            open={password.open}
            onClose={() => setPassword({ open: false })}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%'
            }}
            >
                <div
                style={{ 
                    width: 320, 
                    backgroundColor: theme.palette.mode === 'dark' ? '#171717' : '#fff',
                    borderRadius:'10px',
                    overflowY:'auto',
                    maxHeight: '90vh',
                    display:'flex',
                    flexDirection:'column',
                    padding:'20px'
                }}>
                    <Typography
                    sx={{
                        color:'text.secondary',
                        marginBottom:'10px',
                        textAlign:'center'
                    }}
                    >Proteger acesso com senha</Typography>

                    <TextField
                    type='password'
                    value={password.password}
                    onChange={({target}) => setPassword({...password, password: target.value})}
                    sx={{
                        marginBottom: '10px'
                    }}
                    size='small'
                    label='Senha'
                    helperText={'Vazio p/ deixar sem senha'}
                    />

                    <Button
                    variant='outlined'
                    color='success'
                    onClick={submitPassword}
                    >
                        Enviar
                    </Button>
                </div>
            </Modal>

            <Modal
            open={cecState.address}
            onClose={() => setCecState({...cecState, address: undefined })}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%'
            }}
            >
                <div
                style={{ 
                    // width: '100%', 
                    maxWidth: 360, 
                    backgroundColor: theme.palette.mode === 'dark' ? '#171717' : '#fff',
                    // height:'90vh',
                    borderRadius:'10px',
                    overflowY:'auto',
                    maxHeight: '90vh'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <IconButton onClick={() => power('off')} sx={{m: 3}}>
                            <PowerOffIcon />
                        </IconButton>

                        <IconButton onClick={() => power('on')} sx={{m: 3}}>
                            <PowerIcon />
                        </IconButton>
                    </div>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}>
                        <IconButton disabled onClick={() => volume('down')} sx={{m: 3}}>
                            <VolumeDownIcon />
                        </IconButton>
                        <IconButton disabled onClick={() => volume('mute')} sx={{m: 3}}>
                            <VolumeOffIcon />
                        </IconButton>
                        <IconButton disabled onClick={() => volume('up')} sx={{m: 3}}>
                            <VolumeUpIcon />
                        </IconButton>
                    </div>
                </div>
            </Modal>

            <Modal
            open={cecState.open}
            onClose={() => setCecState({open: false })}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%'
            }}
            >
                <List 
                sx={{ 
                    width: '100%', 
                    maxWidth: 360, 
                    bgcolor: theme.palette.mode === 'dark' ? '#171717' : '#fff',
                    // height:'90vh',
                    borderRadius:'10px',
                    overflowY:'auto',
                    maxHeight: '90vh'
                }}>
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'right'
                    }}
                    >
                        <Button
                        startIcon={<ResetTvIcon />}
                        onClick={active}
                        >
                            Entrada Ativa
                        </Button>
                    </div>
                    {(Array.isArray(state.cec_list) ? state.cec_list : []).filter(({error}) => !error).map((e) => (
                        <ListItem
                        sx={{width:'100%', color:'text.primary'}}
                        secondaryAction={
                            <div 
                            style={{
                                display:'flex', 
                                alignItems:'center'
                            }}>
                                <IconButton 
                                onClick={() => setCecState({ ...cecState, address: e.address })}
                                color="primary">
                                    <ControlCameraIcon />
                                </IconButton>
                                {/* {e.encryption_key === 'on' &&
                                <IconButton>
                                    <LockIcon sx={{fontSize:'15px'}} />
                                </IconButton>}

                                ControlCameraIcon
                                <WifiIndicator 
                                style={{
                                    width:'30px',
                                    height:'20px'
                                }}
                                strength={DBMToSignalStrength(e.signal_level)} /> */}
                            </div>  
                        }>
                            <ListItemButton
                            key={e.device_id}
                            // selected={selectedIndex === 0}
                            // onClick={() => setState({ ...e, open: true })}
                            >
                                {/* <ListItemIcon>
                                <InboxIcon />
                                </ListItemIcon> */}
                                <ListItemText primary={`${e.device}, ${e.vendor} - ${e.power_status}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Modal>

            <Popover 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            anchorEl={connectionAnchor}
            open={connectionAnchor!==null}
            onClose={() => setConnectionAnchor(null)}
            >
                <Box sx={{ width: '100%', maxWidth: 200, bgcolor: 'background.paper' }}>
                    {state.device ? 
                    <List>
                        
                        <ListItem disablePadding>
                            <ListItemButton onClick={props.network}>
                                <ListItemIcon>
                                    <WifiFindIcon />
                                </ListItemIcon>
                                <ListItemText primary="Rede" />
                            </ListItemButton>
                        </ListItem>
                        
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => props.vnc(state)}>
                                <ListItemIcon>
                                    <ConnectedTvIcon />
                                </ListItemIcon>
                                <ListItemText primary="Acesso remoto" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/price/action/shutdown/${state.idtabelapreco}`,{
                                    from: socket.id
                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(() => toast.success('Comando enviado'))
                                .catch(({response}) => toast.error(response.data))
                                .finally(() => setLoading(false))
                            }}>
                            <ListItemIcon>
                                <PowerSettingsNewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Desligar" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/price/action/reboot/${state.idtabelapreco}`,{
                                    from: socket.id
                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(() => toast.success('Comando enviado'))
                                .catch(({response}) => toast.error(response.data))
                                .finally(() => setLoading(false))
                            }}>
                                <ListItemIcon>
                                    <RestartAltIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reiniciar" />
                            </ListItemButton>
                        </ListItem>
                    </List> : 
                    // <List>
                    //     <ListItem disablePadding>
                    //         <ListItemButton onClick={() => {
                    //             setLoading(true)
                    //             axios
                    //             .post(`${api}/api/manage/price/action/monitor/${state.idtabelapreco}`,{
                    //                 from: socket.id
                    //             },{
                    //                 headers: {
                    //                     Authorization: user.token
                    //                 }
                    //             })
                    //             .then(() => toast.success('Comando enviado'))
                    //             .catch(({response}) => toast.error(response.data))
                    //             .finally(() => setLoading(false))
                    //         }}>
                    //             <ListItemIcon>
                    //                 <RestartAltIcon />
                    //             </ListItemIcon>
                    //             <ListItemText primary="Tentar reconexão" />
                    //         </ListItemButton>
                    //     </ListItem>
                    // </List>
                    <></>
                }
                </Box>
            </Popover>

            <Popover 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            anchorEl={appAnchor}
            open={appAnchor!==null}
            onClose={() => setAppAnchor(null)}
            >
                <Box sx={{ width: '100%', maxWidth: 200, bgcolor: 'background.paper' }}>
                    <List>
                        {state.application && 
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/price/action/application/calculate/${state.idtabelapreco}`,{
                                    from: socket.id
                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(() => toast.success('Comando enviado'))
                                .catch(({response}) => toast.error(response.data))
                                .finally(() => setLoading(false))
                            }}>
                                <ListItemIcon>
                                    <VerticalSplitIcon />
                                </ListItemIcon>
                                <ListItemText primary="Calcular" />
                            </ListItemButton>
                        </ListItem>}
                        {state.application && 
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/price/action/reload/${state.idtabelapreco}`,{
                                    from: socket.id
                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(() => toast.success('Comando enviado'))
                                .catch(({response}) => toast.error(response.data))
                                .finally(() => setLoading(false))
                            }}>
                                <ListItemIcon>
                                    <RestartAltIcon />
                                </ListItemIcon>
                                <ListItemText primary="Recarregar" />
                            </ListItemButton>
                        </ListItem>}
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setLoading(true)
                                axios
                                .post(`${api}/api/manage/price/action/application/state/${state.idtabelapreco}`,{
                                    from: socket.id,
                                    state: state.application ? 'off' : 'on'
                                },{
                                    headers: {
                                        Authorization: user.token
                                    }
                                })
                                .then(() => toast.success('Comando enviado'))
                                .catch(({response}) => toast.error(response.data))
                                .finally(() => setLoading(false))
                            }}>
                                <ListItemIcon>
                                    <PowerSettingsNewIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Tentar ${state.application ? 'fechar' : 'abrir'}`} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Popover>

            <div
            style={{
                width:'100%',
                height:'100%',
                display:"flex",
                flexDirection:'column',
                justifyContent: 'space-between'
            }}
            >
                <div>
                    {/* <Typography 
                    sx={{
                        color:'text.primary',
                        textAlign:'left'
                    }}>ID: {state.idtabelapreco}</Typography> */}
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            alignItems:'center'
                        }}
                        >
                            <CopyToClipboard 
                            onCopy={() => {
                                if (!state.ip_address) return
                                toast.success('IP copiado')
                            }}
                                text={state.ip_address}>
                                <Typography
                                color={state.application || state.device ? 'success.main' : 'error'} 
                                sx={{
                                    fontSize:size.width <= 500 && expanded ? '17px' : '13px',
                                    textAlign:'left',
                                    marginRight:'10px',
                                    cursor: state.ip_address && 'pointer'
                                }}>{state.ip_address || 'IP indefinido'}</Typography>
                            </CopyToClipboard>
                            {state.device &&
                            ((size.width <= 500 && expanded) ||
                            size.width > 500
                            ) &&
                            <IconButton onClick={() => setPassword({ open: true })}>
                                {state.password === true ? <LockIcon sx={{fontSize:size.width <= 500 ? '18px' : '15px'}} /> : <LockOpenIcon sx={{fontSize:size.width <= 500 ? '18px' : '15px'}} />}
                            </IconButton>}
                        </div>

                        <Typography 
                        sx={{
                            fontSize:'13px',
                            color:'text.secondary',
                            textAlign:'left'
                        }}>ID: {state.idtabelapreco}</Typography>

                    </div>
                    <Typography 
                    sx={{
                        color:'text.secondary',
                        fontSize:size.width <= 500 && expanded ? '13px' : '11px',
                        textAlign:'left',
                        margin: 0,
                        padding: 0
                    }}>{state.loja ? (state.codloja_int ? `${state.codloja_int} - ${state.loja?.toLowerCase()}` : state.loja?.toLowerCase()) : 'Sem loja'}</Typography>
                    <Typography 
                    sx={{
                        color:'text.secondary',
                        fontSize:size.width <= 500 && expanded ? '13px' : '11px',
                        textAlign:'left',
                        margin: 0,
                        padding: 0
                    }}>{state.area_venda?.toLowerCase() || 'Sem área de venda'}</Typography>
                </div>
                {expanded ? 
                <div
                style={{
                    display:'flex',
                    display:'flex',
                    justifyContent:'space-between',
                    marginTop:'10px'
                }}>
                    <div
                    style={{
                        display:"flex",
                        flexDirection:'column',
                        alignItems:'center',
                        marginRight:'5px'
                    }}
                    >
                        <Circle
                        animate={true}
                        animationDuration="1s"
                        responsive={false}
                        size={size.width <= 500 ? 60 : 50}
                        lineWidth={20}
                        progress={state.cpu_usage ? parseInt(state.cpu_usage) : 0}
                        progressColor="cornflowerblue"
                        bgColor={theme.palette.mode === 'dark' ? "#000" : "whitesmoke"}
                        textColor={theme.palette.mode === 'dark' ? "#fff" : "#000"}
                        textStyle={{
                            font: 'bold 5rem Helvetica, Arial, sans-serif',
                            height:'inherit'
                        }}
                        percentSpacing={10}
                        roundedStroke={true}
                        showPercentage={true}
                        showPercentageSymbol={true}
                        />
                        <Typography 
                        sx={{
                            fontSize:'10px',
                            textAlign:'center'
                        }}>CPU</Typography>
                    </div>
                    <div
                    style={{
                        display:"flex",
                        flexDirection:'column',
                        alignItems:'center',
                        marginRight:'5px'
                    }}
                    >
                        <Circle
                        animate={true}
                        animationDuration="1s"
                        responsive={false}
                        size={size.width <= 500 ? 60 : 50}
                        lineWidth={20}
                        progress={state.memory ? parseInt((state.memory_usage/state.memory) * 100) : 0}
                        progressColor="cornflowerblue"
                        bgColor={theme.palette.mode === 'dark' ? "#000" : "whitesmoke"}
                        textColor={theme.palette.mode === 'dark' ? "#fff" : "#000"}
                        textStyle={{
                            font: 'bold 5rem Helvetica, Arial, sans-serif',
                            height:'inherit'
                        }}
                        percentSpacing={10}
                        roundedStroke={true}
                        showPercentage={true}
                        showPercentageSymbol={true}
                        />
                        <Typography sx={{
                            fontSize:'10px',
                            textAlign:'center'
                        }}>RAM</Typography>
                    </div>
                    <div
                    style={{
                        display:"flex",
                        flexDirection:'column',
                        alignItems:'center'
                    }}
                    >
                        <Circle
                        animate={true}
                        animationDuration="1s"
                        responsive={false}
                        size={size.width <= 500 ? 60 : 50}
                        lineWidth={20}
                        progress={state.temp ? parseInt(state.temp) : 0}
                        progressColor="cornflowerblue"
                        bgColor={theme.palette.mode === 'dark' ? "#000" : "whitesmoke"}
                        textColor={theme.palette.mode === 'dark' ? "#fff" : "#000"}
                        textStyle={{
                            font: 'bold 5rem Helvetica, Arial, sans-serif',
                            height:'inherit'
                        }}
                        percentSpacing={10}
                        roundedStroke={true}
                        showPercentage={true}
                        showPercentageSymbol={false}
                        />
                        <Typography sx={{
                            fontSize:'10px',
                            textAlign:'center'
                        }}>C°</Typography>
                    </div>
                </div> :
                <div
                style={{
                    display:'flex',
                    width:'100%',
                    display:'flex',
                    justifyContent:'space-between',
                    marginTop:'10px'
                }}>
                    <ButtonBase
                    disabled={!state.device}
                    onClick={({currentTarget}) => setConnectionAnchor(currentTarget)}
                    >
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center'
                        }}
                        >
                            {state.device ? <LeakAddIcon sx={{fontSize:size.width <= 500 ? '15px' : '17px'}} color='success' /> : <LeakRemoveIcon sx={{fontSize:size.width <= 500 ? '15px' : '17px'}} color='error' />}
                            <Typography 
                            color={state.device ? 'success.main' : 'error'}
                            sx={{
                                fontSize:size.width <= 500 ? '9px' : '11px'
                            }} >Conexão</Typography>
                        </div>
                    </ButtonBase>
                    <Divider sx={{margin:'0px 5px'}} orientation="vertical" />
                    <ButtonBase
                    disabled={!state.application && !state.device}
                    onClick={({currentTarget}) => setAppAnchor(currentTarget)}
                    >
                    <div
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                    }}
                    >
                        {state.application ? <CastConnectedIcon sx={{fontSize:size.width <= 500 ? '15px' : '17px'}} color='success' /> : <CastIcon sx={{fontSize:size.width <= 500 ? '15px' : '17px'}} color='error' />}
                        <Typography 
                        color={state.application ? 'success.main' : 'error'}
                        sx={{
                            fontSize:size.width <= 500 ? '9px' : '11px'
                        }} >Aplicação</Typography>
                    </div>
                    </ButtonBase>
                    <Divider sx={{margin:'0px 5px'}} orientation="vertical" />
                    <ButtonBase
                    onClick={() => setCecState({ open: state.device && state.cec_list?.length })}
                    disabled={!(state.device && state.cec_list?.length)}
                    >
                    <div
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                    }}
                    >
                        {state.device && state.cec_list?.length ? <TvIcon sx={{fontSize:size.width <= 500 ? '15px' : '17px'}} color='success' /> : <TvOffIcon sx={{fontSize:size.width <= 500 ? '15px' : '17px'}} color='error' />}
                        <Typography 
                        color={state.device && state.cec_list?.length ? 'success.main' : 'error'}
                        sx={{
                            fontSize:size.width <= 500 ? '9px' : '11px'
                        }} >Televisão</Typography>
                    </div>
                    </ButtonBase>
                </div>}
            </div>
            {expanded && <Divider sx={{margin:'0px 10px'}} variant='middle' orientation="vertical" />}
            {expanded &&
            <div
            style={{
                display:'flex',
                width:'100px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between'
            }}>
                <ButtonBase
                disabled={!state.device}
                onClick={({currentTarget}) => setConnectionAnchor(currentTarget)}
                >
                    <div
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center'
                    }}
                    >
                        {state.device ? <LeakAddIcon sx={{fontSize:'17px'}} color='success' /> : <LeakRemoveIcon sx={{fontSize:'17px'}} color='error' />}
                        <Typography 
                        color={state.device ? 'success.main' : 'error'}
                        sx={{
                            fontSize:'11px'
                        }} >Conexão</Typography>
                    </div>
                </ButtonBase>
                <ButtonBase
                disabled={!state.application && !state.device}
                onClick={({currentTarget}) => setAppAnchor(currentTarget)}
                >
                <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}
                >
                    {state.application ? <CastConnectedIcon sx={{fontSize:'17px'}} color='success' /> : <CastIcon sx={{fontSize:'17px'}} color='error' />}
                    <Typography 
                    color={state.application ? 'success.main' : 'error'}
                    sx={{
                        fontSize:'11px'
                    }} >Aplicação</Typography>
                </div>
                </ButtonBase>
                <ButtonBase
                onClick={() => setCecState({ open: state.device && state.cec_list?.length })}
                disabled={!(state.device && state.cec_list?.length)}
                >
                <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}
                >
                    {state.device && state.cec_list?.length ? <TvIcon sx={{fontSize:'17px'}} color='success' /> : <TvOffIcon sx={{fontSize:'17px'}} color='error' />}
                    <Typography 
                    color={state.device && state.cec_list?.length ? 'success.main' : 'error'}
                    sx={{
                        fontSize:'11px'
                    }} >Televisão</Typography>
                </div>
                </ButtonBase>
            </div>}
        </div>
    )
}