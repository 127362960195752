import React, { forwardRef, useContext, useEffect, useRef } from "react"
import { useState } from "react"
import { toast } from "react-toastify"
import { CompanyContext, LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { Autocomplete, Button, FormControlLabel, IconButton, ListItem, ListItemText, Modal, Switch, TextField, Typography } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import Table from '../../table/virtualized'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SyncIcon from '@mui/icons-material/Sync';

import { GridActionsCellItem } from "@mui/x-data-grid"
import Provider from "../../provider"
import Video from "./video"
import Image from "./image"

const Regra = (props) => {
    const [company, setCompany] = useContext(CompanyContext)
    const [size, setSize] = useContext(SizeContext)
    const [application, setApplication] = useState(props.application)
    const [device, setDevice] = useState(props.device)
    const [category, setCategory] = useState(props.category)
    const [tabelas, setTabelas] = useState(props.tabelas)
    const [state, setState] = useState({})

    useEffect(() => setApplication(props.application),[props.application])
    useEffect(() => setDevice(props.device),[props.device])
    useEffect(() => setCategory(props.category),[props.category])
    useEffect(() => setTabelas(props.tabelas),[props.tabelas])

    const save = () => {
        const loja = company.find(({idloja}) => state.idloja === idloja)
        const setor = category.find(({idareavenda}) => state.idareavenda === idareavenda)
        const tabela = tabelas.find(({idtabelapreco}) => state.idtabelapreco === idtabelapreco)
        const aplicacao = application.find(({idaplicacao}) => state.idaplicacao === idaplicacao)
        const dispositivo = device.find(({idaplicacaodispositivo}) => state.idaplicacaodispositivo === idaplicacaodispositivo)

        props.onAdd({
            ...state,
            loja: loja ? `${(loja.codloja_int || loja.idloja)} - ${loja.nome}` : undefined,
            area_venda: setor ? setor.name : undefined,
            tabela: tabela ? `${tabela.idtabelapreco} - ${tabela.loja}, ${tabela.area_venda}` : undefined,
            aplicacao: aplicacao ? aplicacao.nomeaplicacao : undefined,
            dispositivo: dispositivo ? dispositivo.nomedispositivo : undefined
        })
    }

    const theme = useTheme()
    
    return (
        <div
        style={{
            padding:'20px',
            borderRadius:'10px',
            backgroundColor: theme.palette.mode === 'dark' ? '#272727' : '#fff',
            width:'100%'
        }}>
            <div
            style={{
                display:'flex',
                justifyContent:'space-between',
                marginBottom:'20px'
            }}
            >
                <div>
                    <Button
                    startIcon={<DoneIcon />}
                    variant='outlined'
                    color='success'
                    sx={{marginRight:'10px'}}
                    onClick={save}
                    >
                        adicionar
                    </Button>

                    <Button
                    startIcon={<SyncIcon />}
                    color='primary'
                    variant='outlined'
                    onClick={() => props.update()}
                    sx={{marginRight:'10px'}}
                    // size='small'
                    >
                        Atualizar
                    </Button>
                </div>

                <Button
                startIcon={<CloseIcon />}
                variant='outlined'
                color='error'
                onClick={() => props.onClose()}
                >
                    Fechar
                </Button>
            </div>

            {size.width < 800 &&
            <FormControlLabel 
            sx={{color:'text.primary'}}
            control={
            <Switch 
            checked={state.ecommerce}
            onChange={({target}) => setState({...state, ecommerce: target.checked})}
            />
            } label="E-commerce" />}

            <div
            style={{
                display:"flex",
                justifyContent:'space-between',
                width:'100%'
            }}>
                <div
                style={{
                    display:"flex",
                    width: size.width < 800 ? '100%' : 'auto'
                }}
                >
                    {/* <TextField 
                    size='small'
                    label='Nome'
                    sx={{marginRight:'10px'}}
                    value={state.nome}
                    onChange={({target}) => setState({...state, nome: target.value})}
                    /> */}

                    <MobileDatePicker
                    views={['day','month','year']}
                    label="Data Início"
                    value={new Date(state.dtinicio)}
                    onChange={(newValue) => setState({...state, dtinicio: newValue.toISOString()})}
                    renderInput={(params) => <TextField sx={{marginRight:'10px', width: size.width < 800 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                    sx={{marginBottom:'10px'}}
                    />

                    <MobileDatePicker
                    views={['day','month','year']}
                    label="Data Fim"
                    value={new Date(state.dtfim)}
                    onChange={(newValue) => setState({...state, dtfim: newValue.toISOString()})}
                    renderInput={(params) => <TextField sx={{marginRight:'10px', width: size.width < 800 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                    sx={{marginBottom:'10px'}}
                    />

                    <MobileTimePicker
                    views={['hours','minutes']}
                    label="Hora Início"
                    value={new Date(state.hrinicio)}
                    onChange={(newValue) => setState({...state, hrinicio: newValue.toISOString()})}
                    renderInput={(params) => <TextField sx={{marginRight:'10px', width: size.width < 800 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                    sx={{marginBottom:'10px'}}
                    timezone='system'
                    />

                    <MobileTimePicker
                    views={['hours','minutes']}
                    label="Hora Fim"
                    minDate={dayjs('2012-03-01')}
                    value={new Date(state.hrfim)}
                    onChange={(newValue) => setState({...state, hrfim: newValue.toISOString()})}
                    renderInput={(params) => <TextField sx={{marginRight:size.width < 800 ? 0 : '10px', width: size.width < 800 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                    sx={{marginBottom:'10px'}}
                    />
                </div>

                {size.width >= 800 &&
                <FormControlLabel 
                sx={{color:'text.primary'}}
                control={
                <Switch 
                checked={state.ecommerce}
                onChange={({target}) => setState({...state, ecommerce: target.checked})}
                />
                } label="E-commerce" />}

            </div>

            <div
            style={{
                width:'100%',
                display:'flex',
                marginTop:'10px',
                flexWrap:'wrap'
            }}
            >
                <Autocomplete
                disablePortal
                options={company.map(e => ({...e, label: `${(e.codloja_int || e.idloja)} - ${e.nome}`}))}
                // value={value} 
                onChange={(a,e) => setState({...state, idloja: e.idloja })}
                sx={{ width: size.width < 800 ? '100%' : '300px', margin:size.width < 800 ? '5px 0px' : '5px' }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Loja" />}
                />

                <Autocomplete
                disablePortal
                options={category}
                getOptionLabel={e => e.name}
                // value={value} 
                onChange={(a,{idareavenda}) => setState({...state, idareavenda })}
                sx={{ width:size.width < 800 ? '100%' : '300px', margin:size.width < 800 ? '5px 0px' : '5px' }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Área de venda" />}
                />

                <Autocomplete
                disablePortal
                options={tabelas.map(e => ({...e, label: `${e.idtabelapreco} - ${e.loja}, ${e.area_venda}`}))}
                // value={value} 
                onChange={(a,{idtabelapreco}) => setState({...state, idtabelapreco })}
                sx={{ width:size.width < 800 ? '100%' : '300px', margin:size.width < 800 ? '5px 0px' : '5px' }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Tabela de Preço" />}
                />

                <Autocomplete
                disablePortal
                options={application.map(e => ({...e, label: `${e.nomeaplicacao}`}))}
                // value={value} 
                onChange={(a,{idaplicacao}) => setState({...state, idaplicacao })}
                sx={{ width:size.width < 800 ? '100%' : '300px', margin:size.width < 800 ? '5px 0px' : '5px' }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Aplicação" />}
                />

                <Autocomplete
                disablePortal
                options={device.map(e => ({...e, label: `${e.nomedispositivo}`}))}
                // value={value} 
                onChange={(a,{idaplicacaodispositivo}) => setState({...state, idaplicacaodispositivo })}
                sx={{ width:size.width < 800 ? '100%' : '300px', margin:size.width < 800 ? '5px 0px' : '5px' }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Dispositivo" />}
                />
            </div>
            <Typography 
            sx={{
                color:'text.secondary',
                fontSize:'12px',
                margin:'10px'
            }}>
                Data e hora opcionais, se preenchidos substituirão o principal (adicionados na campanha).
            </Typography>
        </div>
    )
}

const CampaignEditor = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [state, setState] = useState({open:false})
    const [produtos, setProdutos] = useState([])
    const [tabelas, setTabelas] = useState([])
    const [category, setCategory] = useState([])
    const [application, setApplication] = useState([])
    const [device, setDevice] = useState([])

    const update = async () => {
        setLoading(true)

        try {
            let response = await axios
            .get(`${api}/api/manage/price/device`,{
                headers: {
                    Authorization: user.token
                }
            })

            setTabelas(response.data.map((e,index) => ({...e, id: e.idtabelapreco})))

            response = await axios
            .get(`${api}/api/manage/settings/sale/area`,{
                headers: {
                    Authorization: user.token
                }
            })

            setCategory(response.data)

            response = await axios
            .get(`${api}/api/manage/integration/application`,{
                headers: {
                    Authorization: user.token
                }
            })
            const app = response.data.filter(({permissao_acaomkt}) => permissao_acaomkt)
            setApplication(app)

            response = await axios
            .get(`${api}/api/manage/integration/application/device`,{
                headers: {
                    Authorization: user.token
                }
            })

            setDevice(response.data.filter(({idaplicacao, autorizado}) => app.find(e => e.idaplicacao === idaplicacao) && autorizado))

            // response = await axios
            // .get(`${api}/api/manage/provider`, {
            //     headers: {
            //         Authorization : user.token
            //     }
            // })

            // setProvider(response.data)

            providerRef.current?.update()

        } catch (err) {
            toast.error(err?.response?.data)
        }

        setLoading(false)
    }

    const open = async (idacaomkt=undefined) => {
        if (idacaomkt) {
            setLoading(true)
            try {
                let response = await axios
                .get(`${api}/api/manage/marketing/campaign?idacaomkt=${idacaomkt}`, {
                    headers: {
                        Authorization: user.token
                    }
                })

                let obj = response.data

                response = await axios
                .get(`${api}/api/manage/marketing/campaign/device/${idacaomkt}`, {
                    headers: {
                        Authorization: user.token
                    }
                })

                obj.rules = response.data.map((e,index) => ({...e, id: index}))

                obj = {
                    ...obj,
                    dtinicio: obj.dtinicio === null ? undefined : obj.dtinicio,
                    dtfim: obj.dtfim === null ? undefined : obj.dtfim,
                    hrinicio: obj.hrinicio === null ? undefined : obj.hrinicio,
                    hrfim: obj.hrfim === null ? undefined : obj.hrfim
                }

                obj = {
                    ...obj,
                    hrinicio: obj.hrinicio ? `2000-11-06T${obj.hrinicio}:00` : undefined,
                    hrfim: obj.hrfim ? `2000-11-06T${obj.hrfim}:00` : undefined,
                }

                setState({...obj, open: true})

            } catch (err) {
                console.log(err)
                toast.error('Erro ao abrir campanha')
            }
            setLoading(false)
        } else {
            setState({open: true})
        }
    }

    React.useImperativeHandle(ref, () => ({ 
        open: (idacaomkt=undefined) => open(idacaomkt),
        close: () => setState({ open: false })
    }))

    useEffect(() => {
        update()
    }, [])

    const save = async () => {
        if ( !state.nome && !state.idacaomkt ) return toast.error('Preencha um nome')
        setLoading(true)
        try {
            let obj = state

            if ( !state.idacaomkt ) {
                const {
                    nome,
                    dtinicio,
                    dtfim,
                    hrinicio,
                    hrfim,
                    ativo,
                    idfornecedor
                } = state

                let response = await axios
                .post(`${api}/api/manage/marketing/campaign`,{
                    nome,
                    dtinicio,
                    dtfim,
                    hrinicio,
                    hrfim,
                    ativo,
                    idfornecedor
                }, {
                    headers: {
                        Authorization: user.token
                    }
                })

                response = await axios
                .get(`${api}/api/manage/marketing/campaign?idacaomkt=${response.data.idacaomkt}`, {
                    headers: {
                        Authorization: user.token
                    }
                })

                obj = {...obj, ...response.data}

                if ( state.videos?.length ) axios
                .post(`${api}/api/manage/marketing/campaign/video/${obj.idacaomkt}`, state.videos, {
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(({data}) => {})
                .catch(({response}) => toast.error(response.data))

            } else {
                const {
                    nome,
                    dtinicio,
                    dtfim,
                    hrinicio,
                    hrfim,
                    ativo,
                    idfornecedor,
                    idacaomkt
                } = state

                await axios
                .put(`${api}/api/manage/marketing/campaign`,{
                    nome,
                    dtinicio,
                    dtfim,
                    hrinicio,
                    hrfim,
                    ativo,
                    idfornecedor,
                    idacaomkt
                }, {
                    headers: {
                        Authorization: user.token
                    }
                })
            }

            await axios
            .post(`${api}/api/manage/marketing/campaign/device/${obj.idacaomkt}`,state.rules || [],{
                headers: {
                    Authorization: user.token
                }
            })
            toast.success("Salvo")
            setState(obj)
            props.onSave()
        } catch (err) {
            toast.error(err?.response?.data)
        }
        setLoading(false)
    }

    const removeRule = id => {
        const rules = []
        for (let i in state.rules) {
            if (state.rules[i].id !== id) rules.push(state.rules[i])
        }
        setState({...state, rules})
    }

    const theme = useTheme()
    const providerRef = useRef()
    const videoRef = useRef()
    const imageRef = useRef()

    return (
        <>
            <Provider 
            ref={providerRef}
            main={false} />

            <Modal
            open={state.openRule}
            onClose={() => setState({...state, openRule: false})}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    // backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    width:'75vw',
                    maxWidth: '1200px'
                }}>
                    <Regra 
                    onClose={() => setState({...state, openRule: false})}
                    application={application}
                    device={device}
                    category={category}
                    tabelas={tabelas}
                    onAdd={e => setState({...state, rules: [...(state.rules || []), e].map((e, index) => ({...e, id: index})), openRule: false})}
                    update={update}
                    />
                </div>
            </Modal>

            <Video 
            onSave={(response, rows) => {
                if ( !response ) setState({...state, videos: rows})
                else toast.success('Salvo')

                videoRef.current?.close()
            }}
            ref={videoRef}
            />

            <Image 
            // onSave={(response, rows) => {
            //     if ( !response ) setState({...state, videos: rows})
            //     else toast.success('Salvo')

            //     videoRef.current?.close()
            // }}
            ref={imageRef}
            />

            <Modal
            open={state.open}
            onClose={() => setState({open: false})}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    width:'90vw',
                    maxWidth:'1200px'
                }}>
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginBottom:'20px'
                    }}
                    >
                        <Button
                        startIcon={<DoneIcon />}
                        variant='outlined'
                        color='success'
                        onClick={save}
                        >
                            Salvar
                        </Button>

                        <Button
                        startIcon={<CloseIcon />}
                        variant='outlined'
                        color='error'
                        onClick={() => setState({open: false})}
                        >
                            Fechar
                        </Button>
                    </div>

                    {size.width < 750 &&
                    <div
                    style={{
                        display:"flex",
                        marginBottom:'10px',
                        justifyContent:'space-between'
                        // flexDirection:"column",
                        // alignItems:'end'
                    }}
                    >
                        <FormControlLabel 
                        sx={{color:'text.primary'}}
                        control={
                        <Switch 
                        checked={state.ativo}
                        onChange={({target}) => setState({...state, ativo: target.checked})}
                        />
                        } label="Ativo" />

                        <div>
                            <Button
                            onClick={() => videoRef.current?.open(state.idacaomkt)}
                            sx={{width:'100px'}}
                            variant='outlined'
                            >
                                videos
                            </Button>
                            <Button
                            onClick={() => imageRef.current?.open(state.idacaomkt)}
                            sx={{width:'100px'}}
                            variant='outlined'
                            >
                                imagens
                            </Button>
                        </div>
                    </div>}

                    <div
                    style={{
                        display:"flex",
                        justifyContent:'space-between',
                        width:'100%',
                        flexWrap:"wrap"
                    }}>
                        <div
                        style={{
                            width: size.width < 750 ? '100%' : 'auto'
                            // display:"flex",
                            // flexWrap:"wrap"
                        }}
                        >
                            <div
                            style={{
                                display:"flex",
                                width: size.width < 750 ? '100%' : 'auto'
                            }}>
                                <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={providerRef.current?.getList() || []}
                                sx={{ width: size.width < 750 ? '100%' : '200px', marginRight:'10px' }}
                                value={
                                    (providerRef.current?.getList() || [])
                                    .find(({idfornecedor}) => (idfornecedor === state.idfornecedor))
                                }
                                getOptionLabel={(option) => option.nome}
                                onChange={(event, value) => setState({...state, idfornecedor: value.idfornecedor})}
                                renderInput={(params) => <TextField {...params} label="Fornecedor" />}
                                size='small'
                                renderOption={(props, option) => (
                                    <ListItem
                                    {...props}
                                    secondaryAction={
                                        <IconButton 
                                        onClick={() => providerRef.current?.open(option.idfornecedor)}
                                        edge="end" aria-label="delete">
                                            <VisibilityOutlinedIcon />
                                        </IconButton>
                                    }
                                    >
                                    <ListItemText
                                        primary={option.nome}
                                    />
                                    </ListItem>
                                )}
                                />

                                <TextField 
                                size='small'
                                label='Nome'
                                sx={{
                                    marginRight:size.width < 750 ? 0 : '10px', 
                                    width:size.width < 750 ? '100%' : '200px'
                                }}
                                value={state.nome}
                                onChange={({target}) => setState({...state, nome: target.value})}
                                />

                            </div>

                            <div
                            style={{
                                display:'flex',
                                marginTop:'10px',
                                width: size.width < 750 ? '100%' : 'auto'
                            }}>
                                <MobileDatePicker
                                views={['day','month','year']}
                                label="Data Início"
                                value={state.dtinicio ? new Date(state.dtinicio) : null}
                                onChange={(newValue) => setState({...state, dtinicio: newValue.toISOString()})}
                                renderInput={(params) => <TextField sx={{marginRight:'10px', width: size.width < 750 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                                sx={{marginBottom:'10px'}}
                                />

                                <MobileDatePicker
                                views={['day','month','year']}
                                label="Data Fim"
                                value={state.dtfim ? new Date(state.dtfim) : null}
                                onChange={(newValue) => setState({...state, dtfim: newValue.toISOString()})}
                                renderInput={(params) => <TextField sx={{marginRight:'10px', width: size.width < 750 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                                sx={{marginBottom:'10px'}}
                                />

                                <MobileTimePicker
                                views={['hours','minutes']}
                                label="Hora Início"
                                value={state.hrinicio ? new Date(state.hrinicio) : null}
                                onChange={(newValue) => setState({...state, hrinicio: newValue.toISOString()})}
                                renderInput={(params) => <TextField sx={{marginRight:'10px', width: size.width < 750 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                                sx={{marginBottom:'10px'}}
                                timezone='system'
                                />

                                <MobileTimePicker
                                views={['hours','minutes']}
                                label="Hora Fim"
                                value={state.hrfim ? new Date(state.hrfim) : null}
                                onChange={(newValue) => setState({...state, hrfim: newValue.toISOString()})}
                                renderInput={(params) => <TextField sx={{marginRight:size.width < 750 ? 0 : '10px', width: size.width < 750 ? '100%' : '110px'}} {...params} size='small' helperText={null} />}
                                sx={{marginBottom:'10px'}}
                                />
                            </div>
                        </div>

                        {size.width >= 750 &&
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:'end',
                            justifyContent:"space-between"
                        }}
                        >
                            <FormControlLabel 
                            sx={{color:'text.primary'}}
                            control={
                            <Switch 
                            checked={state.ativo}
                            onChange={({target}) => setState({...state, ativo: target.checked})}
                            />
                            } label="Ativo" />

                            <div
                            style={{ display:'flex' }}
                            >
                                <Button
                                onClick={() => videoRef.current?.open(state.idacaomkt)}
                                sx={{width:'100px'}}
                                variant='outlined'
                                >
                                    videos
                                </Button>
                                <Button
                                onClick={() => imageRef.current?.open(state.idacaomkt)}
                                sx={{width:'100px'}}
                                variant='outlined'
                                >
                                    imagens
                                </Button>
                            </div>
                        </div>}

                    </div>
                    <div
                    style={{
                        display:'flex',
                        width:'100%',
                        marginTop:'10px',
                        // height:'calc(90vh - 110px)'
                    }}
                    >
                        <Table 
                        list={(state.rules || []).map(e => ({
                            ...e,
                            dtinicio: e.dtinicio ? new Date(e.dtinicio) : undefined,
                            dtfim: e.dtfim ? new Date(e.dtfim) : undefined,
                            hrinicio: e.hrinicio ? new Date(e.hrinicio.indexOf('T') !== -1 ? e.hrinicio : `2000-11-06T${e.hrinicio}:00`).toLocaleTimeString('pt-BR') : '',
                            hrfim: e.hrfim ? new Date(e.hrfim.indexOf('T') !== -1 ? e.hrfim : `2000-11-06T${e.hrfim}:00`).toLocaleTimeString('pt-BR') : ''
                        }))}
                        density={'compact'}
                        header={[
                            {
                                field: 'dtinicio',
                                headerName: 'Início',
                                type:'date'
                            },
                            {
                                field: 'dtfim',
                                headerName: 'Fim',
                                type:'date'
                            },
                            {
                                field: 'hrinicio',
                                headerName: 'Hr. início'
                            },
                            {
                                field: 'hrfim',
                                headerName: 'Hr. fim'
                            },
                            {
                                field: 'loja',
                                headerName: 'Loja'
                            },
                            {
                                field: 'area_venda',
                                headerName: 'Área venda'
                            },
                            {
                                field: 'tabela',
                                headerName: 'Tabela'
                            },
                            {
                                field: 'aplicacao',
                                headerName: 'Aplicação'
                            },
                            {
                                field: 'dispositivo',
                                headerName: 'Dispositivo'
                            },
                            {
                                field: 'ecommerce',
                                headerName: 'E-commerce',
                                type:'boolean'
                            },{
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Excluir',
                                width: 100,
                                cellClassName: 'actions',
                                getActions: ({ id }) => {
                                    
                                    return [
                                        <GridActionsCellItem
                                        icon={<DeleteIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={() => removeRule(id)}
                                        color="error"
                                        />,
                                    ];
                                },
                            }
                        ]}
                        rowsPerPage={5}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        customToolBar={
                            <>
                            <Button 
                            startIcon={
                                <AddIcon />
                            }
                            onClick={() => setState({...state, openRule: true})}
                            >Adicionar regra</Button>
                            </>
                        }
                        // check={true}
                        // selected={state.produto?.map(({id}) => id)}
                        page={0}
                        // onCheck={(list, event, id) => setState({...state, produto: list})}
                        onRowClick={(data, event) => {
                            if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                        }}
                        disableRowSelectionOnClick={true}
                        autoHeight={false}
                        initialState = {{
                            columns: {
                                columnVisibilityModel: {
                                    ean: true,
                                    nome: true,
                                    nomemarca: true,
                                    categoria3: false,
                                    categoria2:true,
                                    categoria1: false,
                                    variavel: false,
                                    codproduto_int: false
                                }
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                            }
                        }}
                        // toolbar={size.width > 650 ? true : false}
                        toolbar
                        sx={{
                            height:'100%'
                        }}
                        height='60vh'
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
})

export default CampaignEditor