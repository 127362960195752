import React, { forwardRef, useEffect, useState } from "react";
import Editable from "../table/editable";
import FilterComponent from "../../utils/filter";

const Product = forwardRef(( props, ref ) => {
    const [list, setList] = useState( props.list || [] )
    const [filtered, setFiltered] = useState( props.list || [] )
    const [selected, setSelected] = useState([])

    useEffect(() => {
        const e = props.selected || []
        setList( props.list || [] )
        setSelected([ ...new Set([ ...selected, ...e.map((i) => ( typeof i === 'object' ? i.id : i ) )])])
    },[ props.list ])

    const onChange = ( s=undefined, l=undefined ) => {
        s = s || selected
        l = l || list
        props.onChange(s.map(e => (l.find(({ id }) => id === ( typeof e === 'object' ? e.id : e )))))
    }

    React.useImperativeHandle(ref, () => ({
        change: e => {
            const s = [ ...new Set([ ...selected, ...e.map((i) => ( typeof i === 'object' ? i.id : i ) )])]
            setSelected(s)
            setList(list.map(a => ( e.find((i) => a.id === ( typeof i === 'object' ? i.id : i ) ) || a )))
            // onChange(s)
        }
    }))

    return (
        <div
        {...props}
        list={undefined}
        onChange={undefined}
        >
            <FilterComponent
            sx={{ margin: '20px 0px' }}
            onFilter={setFiltered}
            items={list}
            customFilter={[{
                label: 'Selecionados',
                onFilter: (e, filter) => {
                    const find = selected.find(i => e.id === i)
                    if ( filter.property === 'sim' && find ) return true
                    if ( filter.property === 'não' && !find ) return true
                    return false
                },
                options: ['sim', 'não']
            }]}
            />

            <Editable 
            rows={filtered}
            columns={[
                {
                    "field": "codproduto_int",
                    "headerName": "Código ERP"
                },{
                    field: 'nome',
                    headerName: 'Nome',
                    align:'left',
                    flex:1
                },{
                    field: 'ean',
                    headerName: 'EAN',
                    align:'left',
                    flex:0.7
                },{
                    field: 'categoria',
                    headerName: 'Categoria',
                    align:'left',
                    flex:0.8
                },{
                    "field": "variacao",
                    "headerName": "Variação",
                    type: 'boolean',
                    flex:0.3
                },{
                    "field": "percentual",
                    "headerName": "% desconto",
                    type: 'number',
                    editable: true
                    // flex:0.3
                },{
                    "field": "desconto",
                    "headerName": "Vlr. desconto",
                    type: 'number',
                    editable: true
                    // flex:0.3
                },{
                    "field": "promocao",
                    "headerName": "Promoção",
                    type: 'number',
                    editable: true
                    // flex:0.3
                }
            ]}
            boxProps={{ style: { height: 'calc(100% - 80px)'}}}
            actionsHeader='Ações'
            density='compact'
            disableDelete
            // disableEdit
            // disableActions
            checkboxSelection
            onRowSelectionModelChange={(a,b,c) => {
                a = a.map(e => (e.id))
                onChange(a)
                setSelected(a)
            }}
            onRowUpdate={(a) => {
                const l = list.map(( e ) => ( a.id === e.id ? a : e ) )
                onChange(undefined, l)
                setList(l)
            }}
            rowSelectionModel={selected}
            actionsWidth={120}
            // actions={({ id, row, ...props }) => {

            //     return [
            //         <GridActionsCellItem 
            //         disabled={!row.idcluster}
            //         icon={<AppSettingsAltIcon />}
            //         label="Delete"
            //         onClick={() => detailRef.current?.open( row.id )}
            //         color="primary"
            //         />
            //     ]
            // }}
            />
        </div>
    )
})

export default Product