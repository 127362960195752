import { Button, Modal } from "@mui/material";
// import Weekday from "./weekday";
import { useContext, useEffect, useRef, useState } from "react";
import ContractForm from "./contractForm";
import AssetsForm from "./assetsForm";
import AssetsCompany from "./assetsCompany";
import { toast } from "react-toastify";
import axios from "axios";
import { api } from "../../../utils/defaults";
import { UserContext } from "../../../context/handleContext";
import AssetContractFormCompany from "./assetContractCompany";

export default function Contract ( props ) {
    const [user] = useContext(UserContext)
    const [area, setArea] = useState([])

    const schedulerRef = useRef();
    const contractRef = useRef()
    const assetRef = useRef()
    const assetCompanyRef = useRef()
    const companyRef = useRef()

    const updateArea = () => {
        // setLoading(true)
        axios
        .get(`${api}/api/manage/settings/sale/area`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setArea(data))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || err?.toString()))
        // .finally(() => setLoading(false))
    }

    const handleOpenScheduler = () => {
        // schedulerRef.current.openModal();
        // schedulerRef.current.setUnavailablePeriods({
        //     Monday: [
        //       { start_time: '08:00', end_time: '10:00' },
        //       { start_time: '14:00', end_time: '16:00' },
        //     ],
        // })
    };

    useEffect(() => {
        updateArea()
    },[])

    return (
        <div>
            <Button variant="contained" onClick={() => contractRef.current.open()}>
                Criar Modelo
            </Button>

            <AssetsCompany
            ref={assetCompanyRef}
            area={area}
            />

            <AssetContractFormCompany 
            ref={companyRef}
            area={area}
            />

            <AssetsForm 
            ref={assetRef}
            onUpdate={() => {
                contractRef.current.updateAssets()
                assetRef.current.close()
            }}
            openCompany={data => assetCompanyRef.current.open(data)}
            />
            
            <ContractForm 
            ref={contractRef}
            openAsset={(idtrademktativo) => assetRef.current.open(idtrademktativo)}
            openSettings={(data) => companyRef.current.open(data)}
            />

        </div>
    )
}