import { 
    forwardRef, 
    useContext, 
    useEffect, 
    useImperativeHandle, 
    useRef, 
    useState 
} from "react";

import { 
    CompanyContext, 
    LoadingContext, 
    UserContext 
} from "../../../context/handleContext";

import { 
    Box, 
    Button, 
    ButtonGroup, 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    IconButton, 
    InputLabel, 
    List, 
    ListItem, 
    MenuItem, 
    Modal, 
    Paper, 
    Select, 
    TextField, 
    Tooltip, 
    Typography, 
    useTheme 
} from "@mui/material";

import Table from '../../table/virtualized'

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


import { toast } from "react-toastify";
import { api } from "../../../utils/defaults";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { float, integer } from "../../../utils/input";

const billingType = {
    'fixed': 'Fixo',
    'action': 'Por Ação',
    'day': 'Por Dia',
    'hour': 'Por Hora',
    'minute': 'Por Minuto'
}

const Form = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user] = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    const [esp, setEsp] = useState([])
    const [cst, setCst] = useState([])
    const [estq, setEstq] = useState([])
    const [area, setArea] = useState([])

    const theme = useTheme()

    useImperativeHandle(ref, () => ({
        open: data => {
            setOpen(true)
            setState(data)
            setCst(data.lista_custos || [])
            setEstq(data.estoque_area_venda || [])
        },
        close: () => setOpen(false)
    }))

    const handleSave = async () => {
        setLoading(true)
        try {
            await axios.put(`${api}/api/manage/marketing/trade/assets/company/${state.idtrademktativo}/${state.idloja}`, {
                ...state,
                estoque: state.controla_estoque_area_venda ? null : state.estoque,
                lista_custos: cst,
                estoque_area_venda: estq.map(e => ({...e, quantidade_disponivel: e.new ? e.quantidade : e.quantidade_disponivel}))
            },{
                headers: {
                    Authorization: user.token
                }
            })
            toast.success('Salvo')
            if ( typeof props.onUpdate === 'function' ) props.onUpdate()
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data || "Erro ao salvar ativo");
        }
        setLoading(false)
    };

    const handleAddCst = () => setCst([...cst,{ name: "", value: 0 }])

    const handleRemoveCst = (index) => {
        const updated = cst.filter((_, i) => i !== index);
        setCst(updated);
    };

    const handleAddEstq = () => setEstq([...estq,{ idareavenda: null, quantidade: 0, quantidade_disponivel: 0, new: true }])

    const handleRemoveEstq = (index) => {
        const updated = estq.filter((_, i) => i !== index);
        setEstq(updated);
    };

    useEffect(() => setArea(props.area),[props.area])
    useEffect(() => {
        if (props.open) return 
        setEstq([])
        setCst([])
        setState({})
    },[props.open])

    return (
        <Modal 
        open={open} 
        onClose={() => setOpen(false)}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                width: "90%",
                maxWidth: 800,
                margin: "auto",
                // marginTop: "5%",
                backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
                color:'text.primary',
                maxHeight:'90vh',
                overflowY:"auto"
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%'
                }}
                >
                    <div
                    style={{ display:'flex', flexDirection:"column"}}
                    >
                        <Typography
                        
                        >
                            Personalizar "{state.nome}"
                        </Typography>
                        <Typography
                        sx={{
                            color:'text.secondary',
                            fontSize:'14px'
                        }}
                        >
                            Loja "{state.nomeloja}"
                        </Typography>
                    </div>

                    <div>
                        <Button
                        color='success'
                        variant='outlined'
                        size='small'
                        sx={{marginRight:'10px'}}
                        startIcon={<SaveAltIcon />}
                        onClick={handleSave}
                        >salvar</Button>

                        <Button
                        color='error'
                        variant='outlined'
                        size='small'
                        startIcon={<CloseIcon />}
                        onClick={() => setOpen(false)}
                        >fechar</Button>
                    </div>
                </div>
                {!state.centraliza_estoque || state.controla_estoque_area_venda ? 
                <>
                    <div
                    style={{
                        display:"flex",
                        alignItems:"center",
                        marginTop:'15px',
                        marginBottom:'10px'
                        // justifyContent:"center"
                    }}
                    >
                        <Typography variant="h6">
                            {state.controla_estoque_area_venda ? 'Estoque por área de venda' : 'Estoque'}
                        </Typography>

                        {state.controla_estoque_area_venda ? 
                        <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={handleAddEstq}
                        size='small'
                        sx={{marginLeft:'10px'}}
                        disabled={!area.filter(e => (
                            !(estq.map(i => i.idareavenda)).includes(e.idareavenda)
                        )).length ||
                        estq.length >= area.length}
                        >
                        Adicionar
                        </Button> : 
                        <TextField 
                        value={state.estoque}
                        onChange={({ target }) => setState({ ...state, estoque: integer(target.value) })}
                        size="small"
                        sx={{width:'150px',marginLeft:'10px'}}
                        label='Estoque'
                        />}
                    </div>
                    {state.controla_estoque_area_venda && estq.length ? 
                    <Paper elevation={3} sx={{padding:'15px 10px 10px 15px'}}>
                        {estq.map((item, index) => (
                            <ListItem 
                            sx={{
                                p:0,
                                paddingBottom:'8px'
                            }}
                            key={index}>
                                <FormControl
                                sx={{ width: "50%", marginRight: 2 }}
                                size='small'
                                >
                                    <InputLabel>Área de Venda</InputLabel>
                                    <Select
                                    value={item.idareavenda}
                                    label="Age"
                                    onChange={(e) => {
                                        const updated = [...estq];
                                        updated[index].idareavenda = e.target.value;
                                        setEstq(updated);
                                    }}
                                    >
                                        {area.filter(e => (
                                            !(estq.map(i => i.idareavenda)).includes(e.idareavenda) ||
                                            e.idareavenda === item.idareavenda
                                        )).map(e => (<MenuItem value={e.idareavenda}>{e.name}</MenuItem>))}
                                    </Select>
                                </FormControl>

                                <TextField
                                label="Quantidade"
                                value={item.quantidade}
                                onChange={(e) => {
                                    const updated = [...estq];
                                    updated[index].quantidade = integer(e.target.value);
                                    setEstq(updated);
                                }}
                                sx={{ width: "40%", marginRight: 2 }}
                                size="small"
                                />
                                <IconButton onClick={() => handleRemoveEstq(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </Paper> : 
                    <></>}
                </> : 
                <></>}

                <Typography variant="h6" sx={{ marginTop: 4 }}>
                    Custo e Venda
                </Typography>

                <div>
                    <Tooltip
                    title='Indica se o ativo pode ter mais de uma ação numa mesma janela. Alternar mídias de fornecedores diferentes no mesmo ativo dentro de um período igual. Por exemplo: Passar mídia dos fornecedores "A", "B", "C" no painel de led entre 12:00 e 13:00'
                    >
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={state.sobrepoe_fornecedor}
                            onChange={(e) => setState({ 
                                ...state, 
                                sobrepoe_fornecedor: e.target.checked 
                            })}
                            // disabled={!ativo.estoque_renovavel}
                            />
                        }
                        label="Permite sobrepor fornecedores em uma ação"
                        />
                    </Tooltip>

                    <Tooltip title='Limite de sobreposição de fornecedores que o ativo aceitará'>
                        <TextField 
                        value={state.limite_fornecedor}
                        onChange={({ target }) => setState({ ...state, limite_fornecedor: integer(target.value) })}
                        size="small"
                        sx={{width:'150px',marginRight:'10px'}}
                        label='Limite Sobreposição'
                        disabled={!state.sobrepoe_fornecedor}
                        />
                    </Tooltip>
                </div>

                <div
                style={{
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"center",
                    marginTop:'10px'
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        alignItems:'center'
                    }}
                    >
                        <TextField 
                        value={state.custo_fixo}
                        onChange={({ target }) => setState({ ...state, custo_fixo: float(target.value) })}
                        size="small"
                        sx={{width:'150px',marginRight:'10px'}}
                        label='Custo Simples'
                        disabled={cst?.length}
                        />

                        <Tooltip title='Periodicidade que irá contabilizar o custo'>
                            <ButtonGroup
                            size='small'
                            >
                                <Button onClick={() => setState({ ...state, tipo_custo: 'fixed'})} variant={state.tipo_custo === 'fixed' ? 'contained' : 'outlined'}>fixo</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'action'})} variant={state.tipo_custo === 'action' ? 'contained' : 'outlined'}>por ação</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'day'})} variant={state.tipo_custo === 'day' ? 'contained' : 'outlined'}>por dia</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'hour'})} variant={state.tipo_custo === 'hour' ? 'contained' : 'outlined'}>por hora</Button>
                                <Button onClick={() => setState({ ...state, tipo_custo: 'minute'})} variant={state.tipo_custo === 'minute' ? 'contained' : 'outlined'}>por minuto</Button>
                            </ButtonGroup>
                        </Tooltip>
                    </div>

                    <Tooltip title='Use para detalhar o custo montando uma composição'>
                        <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={handleAddCst}
                        size='small'
                        >
                        Custo Composto
                        </Button>
                    </Tooltip>
                </div>

                <List
                sx={{
                    m:0,
                    p:0,
                    marginTop:'15px'
                }}
                >
                {cst.map((item, index) => (
                    <ListItem 
                    sx={{
                        p:0,
                        paddingBottom:'8px'
                    }}
                    key={index}>
                        <TextField
                        label="Nome do custo"
                        value={item.name}
                        onChange={(e) => {
                            const updated = [...cst];
                            updated[index].name = e.target.value;
                            setCst(updated);
                        }}
                        sx={{ width: "50%", marginRight: 2 }}
                        size="small"
                        >
                        </TextField>
                        <TextField
                        label="Valor"
                        value={item.value}
                        onChange={(e) => {
                            const updated = [...cst];
                            updated[index].value = float(e.target.value);
                            setCst(updated);
                        }}
                        sx={{ width: "40%", marginRight: 2 }}
                        size="small"
                        />
                        <IconButton onClick={() => handleRemoveCst(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
                </List>

                <div
                style={{
                    display:'flex',
                    alignItems:'center',
                    marginTop:'15px'
                }}
                >
                    <TextField 
                    value={state.valor}
                    onChange={({ target }) => setState({ ...state, valor: float(target.value) })}
                    size="small"
                    sx={{ width:'150px', marginRight:'10px' }}
                    label='Valor'
                    />

                    <Tooltip
                    title='Periodicidade que irá contabilizar o valor'
                    >
                        <ButtonGroup
                        size='small'
                        >
                            <Button onClick={() => setState({ ...state, tipo_valor: 'action'})} variant={state.tipo_valor === 'action' ? 'contained' : 'outlined'}>por ação</Button>
                            <Button onClick={() => setState({ ...state, tipo_valor: 'day'})} variant={state.tipo_valor === 'day' ? 'contained' : 'outlined'}>por dia</Button>
                            <Button onClick={() => setState({ ...state, tipo_valor: 'hour'})} variant={state.tipo_valor === 'hour' ? 'contained' : 'outlined'}>por hora</Button>
                            <Button onClick={() => setState({ ...state, tipo_valor: 'minute'})} variant={state.tipo_valor === 'minute' ? 'contained' : 'outlined'}>por minuto</Button>
                        </ButtonGroup>
                    </Tooltip>
                </div>
            </Box>
        </Modal>   
    )
})

const Company = forwardRef((props, ref) => {
    const [company] = useContext(CompanyContext)
    const [open, setOpen] = useState(false)
    const [except, setExcept] = useState([])
    const [selected, setSelected] = useState([])

    const theme = useTheme()

    useImperativeHandle(ref, () => ({
        open: data => {
            setExcept(data)
            setOpen(true)
        },
        close: () => {
            setOpen(false)
            setSelected([])
        }
    }))

    return (
        <Modal
        open={open}
        onClose={() => {
            setOpen(false)
            setSelected([])
        }}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                width: "90%",
                maxWidth: 700,
                margin: "auto",
                // marginTop: "5%",
                backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
                color:'text.primary',
                maxHeight:'90vh',
                overflowY:"auto"
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%'
                }}
                >
                    <Typography variant="h6" gutterBottom>
                        Adicionar Lojas
                    </Typography>

                    <div>
                        <Button
                        color='success'
                        variant='outlined'
                        size='small'
                        sx={{marginRight:'10px'}}
                        startIcon={<SaveAltIcon />}
                        onClick={() => {
                            if ( typeof props.onSave === 'function' ) props.onSave(selected)
                        }}
                        >salvar</Button>

                        <Button
                        color='error'
                        variant='outlined'
                        size='small'
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            setOpen(false)
                            setSelected([])
                        }}
                        >fechar</Button>
                    </div>
                </div>

                <Table 
                list={company.map(e => ({...e, id: e.idloja})).filter(e => !except.includes(e.id))}
                header={[
                    {
                        "field": "nome",
                        "headerName": "Nome",
                        flex:1
                    },
                    {
                        "field": "cnpj",
                        "headerName": "CPF / CNPJ",
                        flex:1,
                        renderCell: (params) => params.row.cpf || params.row.cnpj
                    },
                    {
                        "field": "codloja_int",
                        "headerName": "Código ERP",
                        flex:0.5
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                check={true}
                selected={selected}
                page={0}
                onCheck={(list, event, id) => setSelected(id)}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar
                sx={{
                    height:'100%'
                }}
                density='compact'
                />
            </Box>
        </Modal> 
    )
})

const AssetsCompany = forwardRef((props, ref) => {
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [state, setState] = useState({})
    const [list, setList] = useState([])
    const [area, setArea] = useState([])

    const update = async (idtrademktativo=undefined) => {
        idtrademktativo = idtrademktativo || state.idtrademktativo
        try {
            setLoading(true)
            const response = await axios.get(`${api}/api/manage/marketing/trade/assets/company/${idtrademktativo}`,{
                headers: {
                    Authorization: user.token
                }
            })
            setList(response.data)
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.response?.data || err?.toString())
        }
        setLoading(false)
    }

    const handleAdd = async ( list ) => {
        setLoading(true)
        try {
            await axios.post(`${api}/api/manage/marketing/trade/assets/company`, list.map(e => ({idloja:e, idtrademktativo:state.idtrademktativo})),{
                headers: {
                    Authorization: user.token
                }
            })
            toast.success('Inserido com sucesso')
            await update()
            companyRef.current.close()
        } catch ( err ) {
            toast.error(err?.response?.data?.message || err?.response?.data || "Erro ao inserir loja");
        }
        setLoading(false)
    }

    const handleDelete = async ( idloja ) => {
        try {
            setLoading(true)
            await axios.delete(`${api}/api/manage/marketing/trade/assets/company/${state.idtrademktativo}/${idloja}`,{
                headers: {
                    Authorization: user.token
                }
            })
            await update()
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.response?.data || err?.toString())
        }
        setLoading(false)
    }

    useEffect(() => setArea(props.area),[props.area])

    useImperativeHandle(ref, () => ({
        open: data => {
            setState({...data, open:true})
            update(data.idtrademktativo)
        }
    }))

    useEffect(() => {
        if ( typeof props.onClose === 'function' && !state.open ) props.onClose()
    },[state.open])

    const theme = useTheme()
    const companyRef = useRef()
    const formRef = useRef()

    return (
        <Modal
        open={state.open}
        onClose={() => setState({ open:false })}
        sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
        >
            <Box
            sx={{
                width: "90%",
                maxWidth: 900,
                margin: "auto",
                // marginTop: "5%",
                backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
                color:'text.primary',
                maxHeight:'90vh',
                overflowY:"auto"
            }}
            >
                <Company 
                ref={companyRef}
                onSave={handleAdd}
                />

                <Form 
                ref={formRef}
                onUpdate={() => {
                    update()
                    formRef.current.close()
                }}
                area={area}
                />

                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width:'100%'
                }}
                >
                    <Typography variant="h6" gutterBottom>
                        Lojas "{state.nome}"
                    </Typography>

                    <div>
                        <Button
                        color='primary'
                        variant='outlined'
                        size='small'
                        sx={{marginRight:'10px'}}
                        startIcon={<AddIcon />}
                        onClick={() => companyRef.current.open(list.map(({ idloja }) => idloja))}
                        >Adicionar</Button>

                        <Button
                        color='error'
                        variant='outlined'
                        size='small'
                        startIcon={<CloseIcon />}
                        onClick={() => setState({ open:false })}
                        >fechar</Button>
                    </div>
                </div>

                <Table 
                list={list}
                header={[
                    {
                        field: "nomeloja",
                        headerName: "Loja",
                        flex: 1
                    },
                    {
                        "field": "cnpj",
                        "headerName": "CPF / CNPJ",
                        flex:1,
                        renderCell: (params) => params.row.cpf || params.row.cnpj
                    },
                    {
                        field: "codloja_int",
                        headerName: "Código ERP",
                        flex: 1
                    },
                    {
                        "field": "custo",
                        "headerName": "Custo",
                        flex:1,
                        renderCell: (params) => params.value ? `R$ ${parseFloat(params.value).toFixed(2)}` : '-',
                        type: 'number'
                    },
                    {
                        field: "tipo_custo",
                        headerName: "Tipo Custo",
                        flex: 1,
                        renderCell: (params) => billingType[params.value] || '-'
                    },
                    {
                        field: "valor",
                        headerName: "Valor",
                        flex: 1,
                        renderCell: (params) => params.value ? `R$ ${parseFloat(params.value).toFixed(2)}` : '-',
                        type: 'number'
                    },
                    {
                        field: "tipo_valor",
                        headerName: "Tipo Valor",
                        flex: 1,
                        renderCell: (params) => billingType[params.value] || '-'
                    },
                    {
                        field: "estoque",
                        headerName: "Estoque",
                        flex: 1,
                        renderCell: (params) => params.value || '-',
                        type: 'number'
                    },
                    {
                        field: "estoque_disponivel",
                        headerName: "Estoque Disponível",
                        flex: 1,
                        renderCell: (params) => params.value || '-',
                        type: 'number'
                    },
                    {
                        field: "sobrepoe_fornecedor",
                        headerName: "Sobrepõe Fornecedor",
                        flex: 1,
                        renderCell: (params) => params.value || '-',
                        type: 'boolean'
                    },
                    {
                        field: "limite_fornecedor",
                        headerName: "Limite Fornecedor",
                        flex: 1,
                        renderCell: (params) => params.value || '-',
                        type: 'boolean'
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Ações',
                        width: 100,
                        cellClassName: 'actions',
                        getActions: ({ id, row }) => {
            
                            return [
                                <GridActionsCellItem
                                    icon={<EditIcon color='primary' />}
                                    label="Abrir"
                                    className="textPrimary"
                                    onClick={() => formRef.current.open(row)}
                                    color="inherit"
                                />,
                                <GridActionsCellItem
                                    icon={<DeleteIcon color='error' />}
                                    label="Adicionar Loja"
                                    className="textPrimary"
                                    onClick={() => handleDelete(row.idloja)}
                                    color="inherit"
                                />
                            ];
                        },
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[20, 50, 100]}
                check={false}
                page={0}
                onRowClick={(data, event) => {
                    if (['button', 'svg', 'path'].indexOf(event.target.localName) !== -1) return;
                    // setTable({
                    //     ...data.row, 
                    //     open: true
                    // });
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            idtrademktativo: false,
                            cnpj: false,
                            codloja_int: false,
                            tipo_custo: false,
                            tipo_valor: false,
                            sobrepoe_fornecedor: false,
                            limite_fornecedor: false
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 }
                    }
                }}
                toolbar={true}
                sx={{
                    height: '100%'
                }}
                density='compact'
                // height={size.width <= 500 ? 'calc(100vh - 160px)' : 'calc(100vh - 140px)'}
                />
            </Box>
        </Modal>
    )
})

export default AssetsCompany