import { Button, FormControlLabel, Modal, Switch, TextField, useTheme, Box, Grid } from "@mui/material";
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { float } from "../../../utils/input";
import { LoadingContext, UserContext } from "../../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";
import { api } from "../../../utils/defaults";
import AssetContractForm from "./assetContractCard";

const ContractForm = forwardRef(({ openAsset, openSettings, ...props }, ref) => {
    const [loading, setLoading] = useContext(LoadingContext);
    const [user] = useContext(UserContext);
    const [state, setState] = useState({});
    const [assets, setAssets] = useState([]);

    const getAssets = async () => {
        setLoading(true);
        try {
            if (!state.idtrademktcontrato) {
                const { data } = await axios.get(`${api}/api/manage/marketing/trade/assets`, {
                    headers: {
                        Authorization: user.token
                    }
                });
                setAssets(data);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.response?.data || "Erro ao listar ativos");
        }
        setLoading(false);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            if (!state.idtrademktcontrato) {
                const { data } = await axios.post(`${api}/api/manage/marketing/trade/contract`, state, {
                    headers: {
                        Authorization: user.token
                    }
                });
                setState({ ...state, ...data });
                await getAssets(data);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.response?.data || "Erro ao salvar");
        }
        setLoading(false);
    };

    useImperativeHandle(ref, () => ({
        open: data => setState({ ...data, open: true }),
        updateAssets: () => getAssets()
    }));

    const theme = useTheme();
    const companyRef = useRef()

    useEffect(() => {
        getAssets();
    }, []);

    return (
        <Modal
            open={state.open}
            onClose={() => setState({})}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    padding: '20px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    borderRadius: '8px',
                    boxShadow: 24,
                    width: { xs: '90%', sm: '1100px' },
                    overflowY:"auto",
                    maxHeight:'90vh'
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            value={state.nomecontrato}
                            onChange={({ target }) => setState({ ...state, nomecontrato: target.value })}
                            size='small'
                            label='Nome do modelo de contrato'
                            fullWidth
                            // helperText='Nome do modelo de contrato'
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            color='success'
                            variant="outlined"
                            size='small'
                            startIcon={<SaveAltIcon />}
                            onClick={handleSave}
                            sx={{ marginRight: '10px' }}
                        >
                            Salvar
                        </Button>
                        <Button
                            color='error'
                            variant="outlined"
                            size='small'
                            onClick={() => setState({})}
                            startIcon={<CloseIcon />}
                        >
                            Fechar
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={3}>
                        <DatePicker
                            label="Início"
                            value={state.inicio ? dayjs(state.inicio) : null}
                            onChange={(newValue) => setState({ ...state, inicio: newValue ? newValue.format('YYYY-MM-DD') : null })}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DatePicker
                            label="Fim"
                            value={state.fim ? dayjs(state.fim) : null}
                            onChange={(newValue) => setState({ ...state, fim: newValue ? newValue.format('YYYY-MM-DD') : null })}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={state.custo}
                            onChange={({ target }) => setState({ ...state, custo: float(target.value) })}
                            size='small'
                            label='Custo'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={state.valor}
                            onChange={({ target }) => setState({ ...state, valor: float(target.value) })}
                            size='small'
                            label='Valor'
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <FormControlLabel
                    sx={{ marginTop: '20px', color: 'text.primary' }}
                    control={<Switch checked={state.ativo} onChange={({ target }) => setState({ ...state, ativo: target.checked })} />}
                    label="Ativo"
                />

                {/* <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={state.custo}
                            onChange={({ target }) => setState({ ...state, custo: float(target.value) })}
                            size='small'
                            label='Custo'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={state.valor}
                            onChange={({ target }) => setState({ ...state, valor: float(target.value) })}
                            size='small'
                            label='Valor'
                            fullWidth
                        />
                    </Grid>
                </Grid> */}

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    {assets.map((e, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <AssetContractForm 
                            openAsset={() => openAsset(e.idtrademktativo)} 
                            openSettings={openSettings}
                            {...e} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Modal>
    );
});

export default ContractForm;