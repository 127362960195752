import React, { forwardRef, useContext, useEffect, useState } from "react";
import { CompanyContext, LoadingContext, UserContext } from "../../context/handleContext";
import { api } from "../../utils/defaults";
import { toast } from "react-toastify";
import axios from "axios";
import Editable from "../table/editable";
import { Autocomplete, Button, Checkbox, FormControlLabel, Modal, TextField, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const Vinculo = forwardRef(( props, ref ) => {
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [company] = useContext(CompanyContext)
    const [state, setState] = useState({ open: false })
    const [device, setDevice] = useState([])
    const [application, setApplication] = useState([])
    const [action, setAction] = useState([])
    const [selected, setSelected] = useState([])
    const [area, setArea] = useState([])
    const [price, setPrice] = useState([])
    const [list, setList] = useState( props.list.map((e) => ({
        ...e,
        id: `a${e.idaplicacao}ad${e.idaplicacaodispositivo}i${e.idloja}ie${e.idlojaecommerce}iea${e.idlojaecommerceacao}`
    })) )

    const update_device = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/application/device`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setDevice( data ))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const update_price = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/price/device`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setPrice( data ))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const update_area = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/settings/sale/area`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setArea( data ))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const update_application = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/application`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setApplication( data ))
        .catch(err => toast.error(err?.response.data?.message || err.response?.data))
        .finally(() => setLoading(false))
    }

    const update_action = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/ecommerce/action?complete=true`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setAction( data ))
        .catch(err => toast.error(err?.response.data?.message || err.response?.data))
        .finally(() => setLoading(false))
    }

    const add = () => {
        setLoading( true )
        axios
        .post(`${api}/api/manage/cluster/${props.idcluster}`,{...state, company: null},{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => {
            toast.success('Adicionado')
            props.update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))
    }

    const remove = () => {
        if ( !selected.length ) return toast.error('Selecione um vínculo')
        setLoading( true )
        axios
        .post(`${api}/api/manage/cluster/${props.idcluster}`,selected.map(i => ({ ...list.find(({ id }) => id === i ), remove: true })),{
            headers: {
                Authorization: user.token
            }
        })
        .then(() => {
            toast.success('Removido')
            props.update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        update_device()
        update_application()
        update_action()
        update_price()
        update_area()
    },[])

    useEffect(() => setList( props.list.map((e) => ({
        ...e,
        id: `a${e.idaplicacao}ad${e.idaplicacaodispositivo}i${e.idloja}ie${e.idlojaecommerce}iea${e.idlojaecommerceacao}ia${e.idareavenda}itp${e.idtabelapreco}`
    })) ) , [ props.list ])

    React.useImperativeHandle( ref, () => ({
        setOpen: (open) => setState(open ? { ...state, open } : {}),
        remove
    }))

    const theme = useTheme()

    return (
        <div
        {...props}
        onChange={undefined}
        >
            <Modal
            open={state.open}
            onClose={() => setState({})}
            sx={{
                justifyContent:'center',
                display:'flex',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    display:'flex',
                    padding:'20px',
                    borderRadius:'5px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    flexDirection:'column'
                }}
                >
                    <FormControlLabel 
                    sx={{color:'text.primary'}}
                    control={
                        <Checkbox 
                        // disabled={!state.tabela_preco}
                        checked={state.tabela_preco} 
                        onChange={({ target }) => setState({ ...state, tabela_preco: target.checked })}
                        />
                        } 
                    label="Mostrador Preço" 
                    />

                    <Autocomplete
                    disablePortal
                    disabled={!state.tabela_preco}
                    options={price}
                    sx={{ width: 300, marginBottom:'5px' }}
                    getOptionLabel={e => `${e.idtabelapreco} - ${e.loja}, ${e.area_venda || 'S/A'}`}
                    onChange={(event, value) => setState({ ...state, idtabelapreco: value?.idtabelapreco || null })}
                    renderInput={(params) => <TextField {...params} label="ID Mostrador Preço" size='small' />}
                    />

                    <Autocomplete
                    disablePortal
                    options={area}
                    sx={{ width: 300, marginBottom:'5px' }}
                    getOptionLabel={e => e.name}
                    onChange={(event, value) => setState({ ...state, idareavenda: value?.idareavenda || null })}
                    renderInput={(params) => <TextField {...params} label="Área de venda" size='small' />}
                    disabled={state.idlojaecommerceacao}
                    />

                    <Autocomplete
                    disablePortal
                    options={company}
                    sx={{ width: 300, marginBottom:'5px' }}
                    getOptionLabel={e => e.nome}
                    onChange={(event, value) => setState({ ...state, company: value })}
                    renderInput={(params) => <TextField {...params} label="Loja" size='small' />}
                    disabled={state.idlojaecommerceacao}
                    />

                    <div
                    style={{
                        display:"flex",
                        justifyContent:'space-between'
                    }}
                    >
                        <FormControlLabel 
                        sx={{color:'text.primary'}}
                        control={
                            <Checkbox 
                            disabled={!state.company}
                            checked={state.idloja} 
                            onChange={() => setState({ ...state, idloja: state.idloja ? null : state.company?.idloja })}
                            />
                            } 
                        label="Loja Física" 
                        />

                        <FormControlLabel 
                        sx={{color:'text.primary'}} 
                        control={
                            <Checkbox 
                            disabled={!state.company || !state.company?.idlojaecommerce}
                            checked={state.idlojaecommerce} 
                            onChange={() => setState({ ...state, idlojaecommerce: state.idlojaecommerce ? null : state.company?.idlojaecommerce })} 
                            />
                        } 
                        label="E-commerce" />
                    </div>

                    {/* <Autocomplete
                    disablePortal
                    options={company.filter(e => e.idlojaecommerce)}
                    onChange={(event, value) => setState({ ...state, idlojaecommerce: value?.idlojaecommerce || null })}
                    sx={{ width: 300, margin: '5px 0px' }}
                    getOptionLabel={e => e.nome}
                    renderInput={(params) => <TextField {...params} label="E-commerce" size='small' />}
                    /> */}

                    <Autocomplete
                    disablePortal
                    options={action.filter(e => e.idlojaecommerceacao)}
                    onChange={(event, value) => setState({ 
                        ...state, 
                        idlojaecommerceacao: value?.idlojaecommerceacao || null,
                        company: null,
                        idloja: null,
                        idlojaecommerce: null
                    })}
                    sx={{ width: 300, margin: '5px 0px' }}
                    getOptionLabel={e => e.nomeacao}
                    renderInput={(params) => <TextField {...params} label="Ação E-commerce" size='small' />}
                    disabled={state.idloja || state.idlojaecommerce}
                    />

                    <Autocomplete
                    disablePortal
                    options={application.filter(e => e.permissao_loja && !e.requer_dispositivo_autorizado)}
                    onChange={(event, value) => setState({ 
                        ...state, 
                        idaplicacao: value?.idaplicacao || null, 
                        idaplicacaodispositivo: null 
                    })}
                    sx={{ width: 300, margin: '5px 0px' }}
                    getOptionLabel={e => e.nomeaplicacao}
                    renderInput={(params) => <TextField {...params} label="Aplicação" size='small' />}
                    disabled={state.idaplicacaodispositivo}
                    />

                    <Autocomplete
                    disablePortal
                    options={device}
                    onChange={(event, value) => setState({ 
                        ...state, 
                        idaplicacaodispositivo: value?.idaplicacaodispositivo || null, 
                        idaplicacao: null 
                    })}
                    sx={{ width: 300, margin: '5px 0px' }}
                    getOptionLabel={e => e.nomedispositivo}
                    renderInput={(params) => <TextField {...params} label="Dispositivo" size='small' />}
                    disabled={state.idaplicacao}
                    />

                    <Button
                    startIcon={<AddIcon />}
                    color='success'
                    size='small'
                    variant="outlined"
                    sx={{marginTop:'20px'}}
                    onClick={add}
                    >
                        Adicionar
                    </Button>
                </div>
            </Modal>

            <Editable 
            rows={list}
            columns={[
                {
                    field: 'idtabelapreco',
                    headerName: 'ID Most. preco',
                    flex:1
                },
                {
                    field: 'nmloja',
                    headerName: 'Loja',
                    flex:1
                },
                {
                    field: 'area_venda',
                    headerName: 'Área venda',
                    flex:1
                },
                {
                    field: 'tabela_preco',
                    headerName: 'Mostrador preco',
                    flex:1,
                    type: 'boolean'
                },
                {
                    field: 'nmlojaecommerce',
                    headerName: 'E-commerce',
                    flex:1
                },
                {
                    field: 'nomeacao',
                    headerName: 'Ação E-commerce',
                    flex:1
                },
                {
                    field: 'nomeaplicacao',
                    headerName: 'Aplicação',
                    flex:1
                },
                {
                    field: 'nomedispositivo',
                    headerName: 'Dispositivo',
                    flex:1
                }
            ]}
            boxProps={{ style: { height: 'calc(100% - 30px)'}}}
            actionsHeader='Ações'
            density='compact'
            // disableDelete
            // disableEdit
            disableActions
            checkboxSelection
            onRowSelectionModelChange={(a,b,c) => setSelected( c )}
            actionsWidth={120}
            />

        </div>
    )
})

export default Vinculo