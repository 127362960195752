import React, { useImperativeHandle, useState, forwardRef } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const WeekdayScheduler = forwardRef((_, ref) => {
    const weekdays = [
        { label: 'Segunda-feira', value: 'Monday' },
        { label: 'Terça-feira', value: 'Tuesday' },
        { label: 'Quarta-feira', value: 'Wednesday' },
        { label: 'Quinta-feira', value: 'Thursday' },
        { label: 'Sexta-feira', value: 'Friday' },
        { label: 'Sábado', value: 'Saturday' },
        { label: 'Domingo', value: 'Sunday' },
    ];

    const [open, setOpen] = useState(false);
    const [schedule, setSchedule] = useState({});
    const [unavailablePeriods, setUnavailablePeriods] = useState({});

    useImperativeHandle(ref, () => ({
        openModal: () => setOpen(true),
        closeModal: () => setOpen(false),
        loadSchedule: (loadedSchedule) => {
        const formattedSchedule = {};
        Object.keys(loadedSchedule).forEach((day) => {
            formattedSchedule[day] = loadedSchedule[day].map((period) => ({
            start_time: dayjs(`2023-01-01T${period.start_time}`),
            end_time: dayjs(`2023-01-01T${period.end_time}`),
            unavailable: false,
            }));
        });
        setSchedule(formattedSchedule);
        },
        setUnavailablePeriods: (periods) => {
        //   const formattedPeriods = {};
        //   Object.keys(periods).forEach((day) => {
        //     formattedPeriods[day] = periods[day].map((period) => ({
        //       start_time: period.start_time,
        //       end_time: dayjs(`2023-01-01T${period.end_time}`),
        //     }));
        //   });
        setUnavailablePeriods(periods);
        },
    }));

    const toggleDay = (day) => {
        setSchedule((prev) => {
        if (prev[day]) {
            const { [day]: _, ...rest } = prev;
            return rest;
        } else {
            return { ...prev, [day]: [] };
        }
        });
    };

    const handlePeriodChange = (day, index, field, value) => {
        setSchedule((prev) => {
        const updatedPeriods = [...prev[day]];
        updatedPeriods[index][field] = value;

        // Check for availability
        updatedPeriods[index].unavailable = isPeriodUnavailable(
            day,
            updatedPeriods[index].start_time,
            updatedPeriods[index].end_time,
            index
        );

        return { ...prev, [day]: updatedPeriods };
        });
    };

    const addPeriod = (day) => {
        setSchedule((prev) => ({
        ...prev,
        [day]: [...(prev[day] || []), { start_time: null, end_time: null, unavailable: false }],
        }));
    };

    const removePeriod = (day, index) => {
        setSchedule((prev) => {
        const updatedPeriods = [...prev[day]];
        updatedPeriods.splice(index, 1);
        return { ...prev, [day]: updatedPeriods };
        });
    };

    function isPeriodUnavailable(day, startTime, endTime, index) {
        if ( !startTime || !endTime ) return true 
        if ( startTime > endTime ) return true
        if ( !startTime.isValid() || !endTime.isValid() ) return true

        const unavailable = [
            ...(unavailablePeriods[day] || []),
            ...(schedule[day] || []).map((e,index) => ({...e,index}))
        ];

        const parseTime = (time) => {
            if ( !time ) return Number.NaN()
            if ( typeof time !== 'string' ) time = time.format('HH:mm')

            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };

        const newStart = parseTime(startTime);
        const newEnd = parseTime(endTime);

        const sortedPeriods = unavailable
        .map(period => ({ start: parseTime(period.start_time), end: parseTime(period.end_time), index: period.index }))
        .sort((a, b) => a.start - b.start);

        for (let i = 0; i < sortedPeriods.length; i++) {
            const period = sortedPeriods[i];
            if (
                newStart < period.end && 
                newEnd > period.start &&
                (period.index === undefined || index !== period.index)
            ) return true

            if (i < sortedPeriods.length - 1) {
                const nextPeriod = sortedPeriods[i + 1];
                if (newStart >= period.end && newEnd <= nextPeriod.start) {
                    continue;
                }
            }
        }

        const firstPeriod = sortedPeriods[0];
        const lastPeriod = sortedPeriods[sortedPeriods.length - 1];
        if (newEnd <= firstPeriod.start || newStart >= lastPeriod.end) {
            return false;
        }

        return false; 
    }

    const handleSave = () => {
        const formattedSchedule = {};
        Object.keys(schedule).forEach((day) => {
        formattedSchedule[day] = schedule[day].filter(({ 
            unavailable, 
            start_time, 
            end_time 
        }) => (
            !unavailable &&
            start_time &&
            end_time
        ))
        .map((period) => ({
            start_time: period.start_time?.format('HH:mm') || null,
            end_time: period.end_time?.format('HH:mm') || null,
            unavailable: period.unavailable || false,
        }));
        });
        if ( typeof _.onChange === 'function' ) _.onChange(formattedSchedule)
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{_.title || 'Configuração de Horários'}</DialogTitle>
        <DialogContent>
            <Typography variant="body1" mb={2}>
            {_.subtitle || 'Selecione os dias da semana e configure os períodos disponíveis.'}
            </Typography>
            <Box>
            {weekdays.map(({ label, value }) => (
                <Box key={value} mb={3}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={!!schedule[value]}
                        onChange={() => toggleDay(value)}
                    />
                    }
                    label={label}
                />
                {schedule[value] && (
                    <Box>
                    {schedule[value].map((period, index) => (
                    <div>
                        <Grid 
                        container 
                        spacing={2} 
                        alignItems="center" 
                        key={index} 
                        mb={1}
                        >
                            <Grid item xs={5}>
                                <TimePicker
                                label="Hora Início"
                                value={period.start_time}
                                onChange={(newValue) =>
                                    handlePeriodChange(value, index, 'start_time', newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    error={period.unavailable}
                                    />
                                )}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TimePicker
                                label="Hora Fim"
                                value={period.end_time}
                                onChange={(newValue) =>
                                    handlePeriodChange(value, index, 'end_time', newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    error={period.unavailable}
                                    />
                                )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                variant="outlined"
                                color="error"
                                onClick={() => removePeriod(value, index)}
                                size="small"
                                >
                                Remover
                                </Button>
                            </Grid>
                        </Grid>
                        {period.unavailable ? 
                        <Typography
                        sx={{
                            position:'relative',
                            top: '-8px',
                            color: '#f44336',
                            fontSize:'15px'
                        }}
                        >Período indisponível</Typography> : 
                        <></>}
                    </div>
                    ))}
                    <Button variant="outlined" onClick={() => addPeriod(value)} size="small">
                        Adicionar Período
                    </Button>
                    </Box>
                )}
                </Box>
            ))}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} size="small">
            Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave} size="small">
            Salvar
            </Button>
        </DialogActions>
        </Dialog>
    );
});

export default WeekdayScheduler;