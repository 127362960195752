import React, { useState, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import { TextField, Autocomplete, Chip, Box, Typography, Button, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const FilterComponent = forwardRef(({ 
        attributes: customAttributes, 
        customFilter, 
        attributeName,
        ...props 
    }, ref) => {
    const [items, setItems] = useState( props.items || [] )
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedAttribute, setSelectedAttribute] = useState('');

    const attributes = customAttributes || [...new Set([...(customFilter?.map(e => e.label) || []), ...Object.keys(items[0] ? Object.entries(items[0]).reduce((a,n) => {
        if ( typeof n[1] === 'object' ) return a
        else return { ...a, [n[0]]: n[1] }
    },{}) : {})])];

    const properties = useMemo(() => {
        const custom = customFilter?.find(({ label }) => label === selectedAttribute )
        if ((!selectedAttribute && !custom) || !items.length) return [];
        if ( custom?.options ) return custom.options
        const uniqueProperties = new Set(items.map(item => item[selectedAttribute]).filter(value => value !== null && value !== undefined));
        if (typeof items[0][selectedAttribute] === 'boolean') {
            return ['sim', 'não'];
        }
        return [...uniqueProperties];
    }, [selectedAttribute, items]);

    const handleAddFilter = ({
        property,
        attribute
    }, api = false) => {
        if (
            ((api && property) || selectedProperty) && 
            ((api && attribute) || selectedAttribute)
        ) {
            const find = selectedFilters.find(e => (
                (e.property === (api ? property : selectedProperty)) &&
                (e.attribute === (api ? attribute : selectedAttribute))
            ))
            if ( find ) return 
            const newFilter = {
                id: uuidv4(),
                property: api ? property : selectedProperty,
                attribute: api ? attribute : selectedAttribute
            };
            setSelectedFilters([...selectedFilters, newFilter]);
            setSelectedProperty('');
            setSelectedAttribute('');
        }
    };

    const handleDeleteFilter = (id) => {
        setSelectedFilters(selectedFilters.filter(filter => filter.id !== id));
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddFilter();
        }
    };

    useEffect(() => setItems( props.items ), [ props.items ])

    const filteredItems = useMemo(() => {
        const l = items.filter(item =>
            selectedFilters.every(filter => {
                let itemValue = item[filter.attribute]

                if (typeof itemValue === 'boolean') itemValue = itemValue ? 'sim' : 'não'
                if ( itemValue === filter.property ) return true
                if ( customFilter ) return customFilter.every( custom => custom.onFilter( item, filter ))
                
                return false
            })
        );

        props.onFilter( l )

        return l
    }, [selectedFilters, items]);

    const availableProperties = properties.filter(property => 
        !selectedFilters.some(filter => filter.attribute === selectedAttribute && filter.property === property)
    );

    useImperativeHandle(ref, () => ({
        addFilter: ( data ) => handleAddFilter(data, true)
    }))

    return (
        <Box sx={{
            color:'text.primary',
            ...props.sx
        }} p={0}>
            <Grid container spacing={2} alignItems="center">
                {props.startButton ? 
                <Grid 
                sx={{
                    height:'100%'
                }}
                item 
                xs={props.xs4 || 2}>
                    {props.startButton}
                </Grid> : <></>}

                <Grid item xs={props.startButton ? (props.xs1 || 3) : (props.xs1 || 4)}>
                    <Autocomplete
                        options={attributes}
                        value={selectedAttribute}
                        getOptionLabel={ attributeName ? e => (attributeName[e] || '') : undefined}
                        onChange={(event, newValue) => setSelectedAttribute(newValue)}
                        renderInput={(params) => <TextField {...params} label="Atributo" variant="outlined" size="small" onKeyPress={handleKeyPress} />}
                    />
                </Grid>

                <Grid item xs={props.xs2 || 4}>
                    <Autocomplete
                        options={availableProperties}
                        value={selectedProperty}
                        onChange={(event, newValue) => setSelectedProperty(newValue)}
                        renderInput={(params) => <TextField {...params} label="Propriedade" variant="outlined" size="small" onKeyPress={handleKeyPress} />}
                        disabled={!selectedAttribute}
                    />
                </Grid>

                <Grid item xs={props.startButton ? (props.xs3 || 3) : (props.xs3 || 4)}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddFilter}
                        disabled={!selectedAttribute || !selectedProperty}
                        fullWidth
                        size="small"
                        sx={{ height: '40px' }}
                    >
                        Adicionar Filtro
                    </Button>
                </Grid>
            </Grid>
            {selectedFilters.length ?
            (<Box mt={2}>
                <Typography variant="h6">Filtros Aplicados:</Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                    {selectedFilters.map(filter => (
                        <Chip
                            key={filter.id}
                            label={`${attributeName ? attributeName[filter.attribute] : filter.attribute}: ${filter.property}`}
                            onDelete={() => handleDeleteFilter(filter.id)}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                </Box>
            </Box>) : <></>}
            {/* <Box mt={4}>
                <Typography variant="h6">Itens Filtrados:</Typography>
                {filteredItems.map(item => (
                    <Box key={item.unique_id} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                        <Typography>{JSON.stringify(item, null, 2)}</Typography>
                    </Box>
                ))}
            </Box> */}
        </Box>
    );
})

export default FilterComponent;