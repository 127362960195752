import React, { 
    useContext,
    useState,
    forwardRef
} from "react";

import { 
    Autocomplete,
    Button, 
    Checkbox, 
    FormControlLabel, 
    IconButton, 
    ListItem, 
    ListItemText, 
    Modal, 
    Switch, 
    TextField, 
    Typography
} from "@mui/material";

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import DoneIcon from '@mui/icons-material/Done';

import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';

import { 
    CompanyContext,
    LoadingContext,
    SizeContext, 
    UserContext
} from "../../../context/handleContext";

import Table from '../../table/virtualized'
import { useTheme } from '@mui/material/styles';
import { useReducer } from "react";
import axios from "axios";
import { api } from "../../../utils/defaults";
import { toast } from "react-toastify";
import { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Editor from './editor'
import { useRef } from "react";
import Editable from "../../table/editable";

const tamanhos_exemplo = {
    "Cabeçalho": {
        "Logo": 12,
        "Fonte oferta": 4,
        "Fonte produto": 5,
        "Fonte centavos": 2.5,
        "Fonte Fila": 7
    },
    "Tabela": {
        "Fonte Cabeçalho": 3,
        "Fonte Corpo": 3,
        "Fonte Oferta": 2
    },
    "Marketing": {
        "Largura Video": 30,
        "Altura Video": 30,
        "Tamanho QR Code": 15,
        "Fonte QR Code": 1
    }
}

const Categorias = (props) => {
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [open, setOpen] = useState(props.open)
    const [list, setList] = useState(props.data || [])

    const theme = useTheme()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/settings/sale/area`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => props.setData(response.data.map((e) => ({ ...e, id: e.idareavenda }))))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = ( updatedRow ) => {
        return new Promise((resolve, reject) => {
            if ( !updatedRow.name ) return toast.error('Insira um nome para salvar')
                
            if ( updatedRow.idareavenda ) {
                setLoading(true)
                axios
                .put(`${api}/api/manage/settings/sale/area/${updatedRow.idareavenda}`, updatedRow ,{ headers: { Authorization: user.token } })
                .then(({ data }) => resolve(updatedRow))
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar')
                    reject()
                })
                .finally(() => setLoading( false ))
            } else {
                setLoading(true)
                axios
                .post(`${api}/api/manage/settings/sale/area`, updatedRow ,{ headers: { Authorization: user.token } })
                .then(({ data }) => resolve([...list, { ...updatedRow, ...data, id: data.idareavenda }]))
                .catch(err => {
                    toast.error(err?.response?.data?.message || err?.response?.data || 'Erro ao salvar')
                    reject()
                })
                .finally(() => setLoading( false ))
            }
        })
    }

    useEffect(() => setList(props.data), [props.data])

    useEffect(() => {
        if (!list.length) update()
    }, [])

    return (
        <>
            <Button
            startIcon={<CategoryIcon />}
            color='primary'
            variant='contained'
            onClick={() => setOpen(true)}
            sx={{marginRight:'10px'}}
            size='small'
            >
                Setores
            </Button>

            <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                width: '100vw',
                height: '100vh',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    height:'fit-content',
                    margin:'10px',
                    width: '100%',maxWidth:'800px'
                }}
                >
                    <Editable 
                    rows={list}
                    columns={[
                        {
                            "field": "name",
                            "headerName": "Nome",
                            flex: 0.7,
                            editable: true
                        },
                        {
                            "field": "description",
                            "headerName": "Descrição",
                            flex: 1,
                            editable: true
                        },
                        {
                            field: 'active',
                            headerName: 'Ativo',
                            editable: true,
                            type: 'boolean'
                        }
                    ]}
                    addButton
                    boxProps={{ style: { height: 'calc(100% - 60px)'}}}
                    actionsHeader='Ações'
                    density='compact'
                    disableDelete
                    onRowUpdate={save}
                    // onRowDelete={onDelete}
                    actionsWidth={120}
                    toolbarChildren={<>
                        <Button
                            startIcon={<SyncIcon />}
                            color='primary'
                            onClick={update}
                            sx={{marginRight:'10px'}}
                            size='small'
                            >
                                Atualizar
                        </Button>
                    </>}
                    actions={({ id, row, ...props }) => {

                        return [
                            // <GridActionsCellItem 
                            // disabled={!row.idcluster}
                            // icon={<AppSettingsAltIcon />}
                            // label="Delete"
                            // onClick={() => detailRef.current?.open( row.id )}
                            // color="primary"
                            // />
                        ]
                    }}
                    />
                </div>
            </Modal>
        </>
    )
}

const Home = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [size] = useContext(SizeContext)
    const [user] = useContext(UserContext)
    const [company] = useContext(CompanyContext)
    const [table, setTable] = useState({ tamanhos: tamanhos_exemplo })
    const [list, setList] = useState([])
    const [category, setCategory] = useState([])
    const [preferences, setPreferences] = useState([])

    useEffect(() => {
        setLoading(true)
        // axios
        // .get(`${api}/api/manage/price/font`,{
        //     headers: {
        //         Authorization: user.token
        //     }
        // })
        // .then(({data}) => setFont(data))
        // .catch(({response}) => toast.error(response.data))
        // .finally(() => setLoading(false))

        const controller = update()

        if (props.main  === false) {
            axios
            .get(`${api}/api/manage/price/preferences`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => setPreferences(data))
            .catch(err => toast.error(err.response.data))

            axios
            .get(`${api}/api/manage/price/category`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => setCategory(data))
            .catch(err => toast.error(err.response.data))
        }

        return () => {
            controller.abort()
        }
    },[])

    const update = (idtabelapreco=undefined) => {
        const controller = new AbortController()

        setLoading(true)
        axios
        .get(`${api}/api/manage/price/device${idtabelapreco ? `?idtabelapreco=${idtabelapreco}` : ''}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({data}) => {
            if (idtabelapreco) setTable({...data, open: true})
            else setList(data)
        })
        .catch(({response}) => toast.error(response?.data))
        .finally(() => setLoading(false))

        return controller
    }

    const save = () => {
        if (table.idtabelapreco) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/price/device`,table,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update()
                update(data.idtabelapreco)
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/price/device`,table,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update()
                update(data.idtabelapreco)
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        }
    }

    const leave = (idtabelapreco) => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/device/leave/${idtabelapreco}`,table,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            update()
            toast.success('Sucesso')
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const theme = useTheme()
    const editorRef = useRef()

    React.useImperativeHandle(ref, () => ({ 
        open: idtabelapreco => update(idtabelapreco)
    }))

    return (
        <>
            <Modal
            open={table.open}
            onClose={() => setTable({open: false})}
            sx={{
                width:'100%',
                height:'100%',
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    // flexWrap:"wrap",
                    overflowY:'auto',
                    maxHeight:'95vh',
                    width: size.width <= 500 ? '95%' : "auto",
                    overflowX: 'hidden',
                    flexDirection: size.width <= 500 ? 'column' : 'row'
                }}
                >
                    <div
                    style={{}}
                    >
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            marginBottom:'20px'
                        }}
                        >
                            <Button
                            size='small'
                            variant="contained"
                            color='success'
                            onClick={save}
                            >
                                salvar
                            </Button>

                            <Button
                            size='small'
                            variant="contained"
                            color='error'
                            onClick={() => setTable({open: false})}
                            >
                                fechar
                            </Button>
                        </div>

                        <Autocomplete
                        disablePortal
                        options={preferences}
                        sx={{ width: '350px', marginBottom:'10px' }}
                        value={
                            preferences
                            .find(({idtabelaprecopreferencia}) => (idtabelaprecopreferencia === table.idtabelaprecopreferencia))
                        }
                        getOptionLabel={(option) => option.nomepreferencia}
                        onChange={(event, value) => setTable({...table, idtabelaprecopreferencia: value?.idtabelaprecopreferencia})}
                        renderInput={(params) => <TextField {...params} label="Tema" />}
                        size='small'
                        renderOption={(props, option) => (
                            // <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            //     {option.nomepreferencia}
                            // </Box>
                            <ListItem
                            {...props}
                            secondaryAction={
                                <IconButton 
                                onClick={() => editorRef.current?.open(option.idtabelaprecopreferencia)}
                                edge="end" aria-label="delete">
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                            }
                            >
                            {/* <ListItemAvatar>
                                <Avatar>
                                <FolderIcon />
                                </Avatar>
                            </ListItemAvatar> */}
                            <ListItemText
                                primary={option.nomepreferencia}
                                // secondary={secondary ? 'Secondary text' : null}
                            />
                            </ListItem>
                        )}
                        />

                        <Autocomplete
                        disablePortal
                        options={company.map((e) => ({...e, label: e.nome}))}
                        sx={{ width: '350px', marginBottom:'10px' }}
                        value={
                            company.map((e) => ({...e, label: e.nome}))
                            .find(({idloja}) => (idloja === table.idloja))
                        }
                        onChange={(event, value) => setTable({...table, idloja: value?.idloja})}
                        renderInput={(params) => <TextField {...params} label="Loja" />}
                        size='small'
                        />

                        <Autocomplete
                        disablePortal
                        options={category.map((e) => ({...e, label: e.name}))}
                        sx={{ width: '350px' }}
                        value={
                            category.map((e) => ({...e, label: e.name}))
                            .find(({idareavenda}) => (idareavenda === table.idareavenda))
                        }
                        onChange={(event, value) => setTable({...table, idareavenda: value?.idareavenda})}
                        renderInput={(params) => <TextField {...params} label="Área de venda" />}
                        size='small'
                        />

                        <div
                        style={{
                            display:'flex',
                            justifyContent: 'space-between',
                            margin:'10px 0px 0px 0px'
                        }}>
                            <TextField 
                            label='Latitude'
                            sx={{width:'130px'}}
                            size='small'
                            value={table.lat}
                            onChange={({target}) => setTable({...table, lat: target.value})}
                            />

                            <TextField 
                            label='Longitude'
                            sx={{width:'130px'}}
                            size='small'
                            value={table.lng}
                            onChange={({target}) => setTable({...table, lng: target.value})}
                            />
                        </div>

                        <div
                        style={{
                            display:'flex',
                            justifyContent: 'space-between',
                            margin:'10px 0px 0px 10px'
                        }}
                        >
                            <FormControlLabel 
                            sx={{
                                color: 'text.primary'
                            }}
                            control={
                                <Checkbox 
                                color='primary' 
                                onChange={({target}) => setTable({...table, mostrar_preco_ecommerce: target.checked})}
                                checked={table.mostrar_preco_ecommerce}
                                // disabled
                                />
                            } 
                            label="Mostrar E-commerce" />

                        </div>

                    </div>
                </div>
            </Modal>

            {props.main === false ? <></> : 
            <div  
            style={{
                overflowY:'auto',
                padding:size.width <= 500 ? '5px' : '20px'
            }}
            >
                <div
                style={{
                    width:'100%',
                    // margin: '10px 0px',
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom: '10px',
                    flexWrap:"wrap"
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: size.width <= 500 && '100%',
                        marginBottom: size.width <= 500 && '10px'
                    }}
                    >
                        <Button
                        startIcon={<AddIcon />}
                        color='success'
                        variant='outlined'
                        onClick={() => setTable({tamanhos: tamanhos_exemplo, open: true})}
                        sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Adicionar
                        </Button>
    
                        <Button
                        startIcon={<SyncIcon />}
                        color='primary'
                        variant='outlined'
                        onClick={() => update()}
                        // sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Atualizar
                        </Button>
                    </div>
    
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: size.width <= 500 && '100%'
                    }}>
                        <Categorias 
                        data={category}
                        setData={e => setCategory(e)}
                        />
    
                        <Editor 
                        ref={editorRef}
                        showButton
                        data={preferences}
                        setData={e => setPreferences(e)}
                        />
                    </div>
    
                </div>
    
                <Table 
                list={list}
                density='compact'
                header={[
                    {
                        "field": "idtabelapreco",
                        "headerName": "ID"
                    },
                    {
                        "field": "loja",
                        "headerName": "Loja",
                        flex: 1
                    },
                    {
                        "field": "area_venda",
                        "headerName": "Área de venda",
                        flex: 1
                    },
                    {
                        "field": "nomepreferencia",
                        "headerName": "Tema",
                        flex: 1
                    }, {
                        "field": "mostrar_preco_ecommerce",
                        "headerName": "Mostra Ecommerce",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "application",
                        "headerName": "App Online",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "device",
                        "headerName": "Disp. online",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "is_used",
                        "headerName": "Em uso",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "key_code",
                        "headerName": "Chave",
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <CopyToClipboard 
                                onCopy={() => {
                                    toast.success('Copiado')
                                }}
                                text={params.value}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ marginLeft: 16 }}
                                    tabIndex={params.hasFocus ? 0 : -1}
                                    // onClick={() => console.log()}
                                >
                                    Copiar
                                </Button>
                                </CopyToClipboard>
                            )
                        },
                    }, {
                        "field": "codloja_int",
                        "headerName": "Cod. Loja"
                    }, {
                        "field": "ip",
                        "headerName": "IP"
                    }, {
                        "field": "mac",
                        "headerName": "MAC"
                    }, {
                        "field": "lat",
                        "headerName": "Latitude"
                    }, {
                        "field": "lng",
                        "headerName": "Longitude"
                    }, {
                        "field": "nomedispositivo",
                        "headerName": "Nome",
                        hide: true,
                        flex: 0.3
                    }, {
                        "field": "",
                        "headerName": "Liberar",
                        renderCell: (params) => {
                            return (
                                <IconButton
                                color='success'
                                onClick={() => leave(params.row.idtabelapreco)}
                                disabled={!params.row.is_used}
                                >
                                    <GppGoodOutlinedIcon />
                                </IconButton>
                            )
                        }
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[10, 50, 100]}
                check={false}
                page={0}
                // onCheck={console.log}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                    setTable({
                        ...data.row, 
                        open: true
                    })
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            idtabelapreco: size.width > 500,
                            loja: true,
                            setor: true,
                            mostrar_preco_ecommerce: size.width > 500,
                            is_used: size.width > 500,
                            key_code: true,
                            codloja_int: false,
                            ip: false,
                            mac: false,
                            lat: false,
                            lng: false,
                            nomedispositivo: false,
                            Nome: false
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                height={size.width <= 500 ? 'calc( 100vh - 160px )' : 'calc( 100vh - 140px )'}
                />
            </div>}
        </>
    )
})

export default Home