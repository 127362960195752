import React, { useState, useEffect, useContext, useRef } from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    Grid, 
    Paper, 
    TextField, 
    IconButton, 
    Modal, 
    useTheme ,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import { 
    PieChart, 
    Pie, 
    Cell, 
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    Tooltip, 
    ResponsiveContainer, 
    ComposedChart,
    Line,
    Label,
    LabelList
} from 'recharts';

import axios from 'axios';
import { api } from '../../../utils/defaults';
import { toast } from 'react-toastify';
import { LoadingContext, UserContext } from '../../../context/handleContext';
import Table from '../../table/virtualized'
import AssetsForm from './assetsForm';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AssetsCompany from './assetsCompany';

const CreateEntityModal = ({ open, onClose, type, onUpdate }) => {
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [items, setItems] = useState([]);
    const [currentItem, setCurrentItem] = useState({ id: null, name: '', description: '', module: 'tradeMKT' });
    const [isEditing, setIsEditing] = useState(false);
  
    const theme = useTheme();
  
    const endpointMap = {
      asset: 'marketing/trade/assets/type',
      group: 'marketing/trade/group',
      type: 'marketing/trade/type',
      area: 'settings/sale/area'
    };
  
    const fetchItems = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}/api/manage/${endpointMap[type]}`,{
                headers: {
                    Authorization: user.token
                }
            });
            setItems(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data || 'Error fetching items:'+error?.toString());
        }
        setLoading(false)
    };
  
    const handleSave = async () => {
        if ( !currentItem.name ) return toast.error('Preencha o nome')
        setLoading(true)
        try {
            if (isEditing) {
            // Update existing item
            await axios.put(`${api}/api/manage/${endpointMap[type]}/${currentItem.all_category_id}`, currentItem,{
                headers: {
                    Authorization: user.token
                }
            });
            } else {
            // Create new item
            await axios.post(`${api}/api/manage/${endpointMap[type]}`, currentItem,{
                headers: {
                    Authorization: user.token
                }
            });
            }
            fetchItems();
            handleReset();
            toast.success('Sucesso')
            if ( typeof onUpdate === 'function' ) onUpdate(type)
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data || 'Error saving item:'+error?.toString());
        }
        setLoading(false)
    };
  
    const handleEdit = (item) => {
      setCurrentItem(item);
      setIsEditing(true);
    };
  
    const handleReset = () => {
      setCurrentItem({ id: null, name: '', description: '', module: 'tradeMKT' });
      setIsEditing(false);
    };
  
    useEffect(() => {
      if (open) fetchItems();
    }, [open]);
  
    return (
      <Modal 
      open={open} 
      onClose={onClose}
      sx={{
        display:'flex',
        justifyContent:"center",
        alignItems:'center'
      }}
      >
        <Box
          sx={{
            width: '90%',
            maxWidth: 600,
            margin: 'auto',
            backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
            padding: '10px 20px',
            borderRadius: 2,
            boxShadow: 3,
            color: 'text.primary',
            maxHeight:'90vh',
            overflowY:"auto"
          }}
        >
            <div
            style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                marginBottom:'10px'
            }}
            >
                <Typography variant="h6">
                    Gerenciar {type === 'asset' ? 'Ativos' : type === 'group' ? 'Grupos de Mídia' : type === 'area' ? 'Áreas de Venda' : 'Tipos de Mídia'}
                </Typography>

                <Button
                startIcon={<CloseIcon />}
                color='error'
                onClick={onClose}
                >
                    fechar
                </Button>
            </div>
          
            <Box sx={{ marginBottom: 2 }}>
                <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                value={currentItem.name}
                onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                sx={{ marginBottom: 2 }}
                size='small'
                />
                <TextField
                label="Descrição"
                variant="outlined"
                fullWidth
                value={currentItem.description}
                onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })}
                multiline
                minRows={2}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                // disabled={!currentItem.name || !currentItem.description}
                >
                {isEditing ? 'Atualizar' : 'Criar'}
                </Button>
                {isEditing && (
                <Button variant="outlined" color="secondary" onClick={handleReset}>
                    Cancelar
                </Button>
                )}
            </Box>
            <Typography variant="subtitle1" gutterBottom>
                Lista de {type === 'asset' ? 'Ativos' : type === 'group' ? 'Grupos de Mídia' : type === 'area' ? 'Áreas de Venda' : 'Tipos de Mídia'}
            </Typography>
            <List
            sx={{
                overflowY:"auto",
                maxHeight:'100%'
            }}
            >
                {items.map((item) => (
                <ListItem
                    key={item.id}
                    secondaryAction={
                    <IconButton edge="end" onClick={() => handleEdit(item)}>
                        <EditIcon />
                    </IconButton>
                    }
                >
                    <ListItemText primary={item.name} secondary={item.description} />
                </ListItem>
                ))}
            </List>
        </Box>
      </Modal>
    );
};

const billingType = {
    'fixed': 'Fixo',
    'action': 'Por Ação',
    'day': 'Por Dia',
    'hour': 'Por Hora',
    'minute': 'Por Minuto'
}

const customLabel = props => {
    console.log(props)
    return (<></>)
}

const Assets = () => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user] = useContext(UserContext)
    const [ativos, setAtivos] = useState([]);
    const [modalType, setModalType] = useState(null);
    const [area, setArea] = useState([])

    const [dataBar, setDataBar] = useState([]);
    const [loadingBar, setLoadingBar] = useState(false);

    const [dataStockCompany, setDataStockCompany] = useState([]);
    const [loadingStockCompany, setLoadingStockCompany] = useState(false);

    const [dataPie, setDataPie] = useState([]);
    const [loadingPie, setLoadingPie] = useState(false);

    const [dataCostValueCompany, setDataCostValueCompany] = useState([]);
    const [loadingCostValueCompany, setLoadingCostValueCompany] = useState(false);

    const fetchBarData = async () => {
        setLoadingBar(true);
        try {
            const response = await axios.get(`${api}/api/manage/marketing/dashboard/trade/assets/stockpersalearea`,{
                headers: {
                    Authorization: user.token
                }
            });
            setDataBar(response.data.map(e => ({...e, quantidade: parseInt(e.quantidade)})));
        } catch (error) {
            console.error("Erro ao carregar os dados do gráfico de Estoque por Área de Venda", error);
        } finally {
            setLoadingBar(false);
        }
    };

    const fetchStockCompanyData = async () => {
        setLoadingStockCompany(true);
        try {
            const response = await axios.get(`${api}/api/manage/marketing/dashboard/trade/assets/stockpercompany`,{
                headers: {
                    Authorization: user.token
                }
            });
            setDataStockCompany(response.data.map(e => ({...e, estoque: parseInt(e.estoque)})));
        } catch (error) {
            console.error("Erro ao carregar os dados do gráfico de Estoque por Empresa", error);
        } finally {
            setLoadingStockCompany(false);
        }
    };

    const fetchPieData = async () => {
        setLoadingPie(true);
        try {
            const response = await axios.get(`${api}/api/manage/marketing/dashboard/trade/assets/type`,{
                headers: {
                    Authorization: user.token
                }
            });
            setDataPie(response.data.map(e => ({...e, estoque: parseInt(e.estoque)})));
        } catch (error) {
            console.error("Erro ao carregar os dados dos gráficos de Tipos de Ativo x Tipos de Mídia", error);
        } finally {
            setLoadingPie(false);
        }
    };

    const fetchCostValueCompanyData = async () => {
        setLoadingCostValueCompany(true);
        try {
            const response = await axios.get(`${api}/api/manage/marketing/dashboard/trade/assets/costpersale`,{
                headers: {
                    Authorization: user.token
                }
            });
            setDataCostValueCompany(response.data.map(e => ({...e, custo: parseFloat(e.custo), valor: parseFloat(e.valor)})));
        } catch (error) {
            console.error("Erro ao carregar os dados do gráfico de Relação de Custo x Valor de Venda Por Empresa", error);
        } finally {
            setLoadingCostValueCompany(false);
        }
    };

    const handleOpenModal = (type) => {
        setModalType(type);
    };

    const handleCloseModal = () => {
        setModalType(null);
    };

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/marketing/trade/assets`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setAtivos(data.map(e => ({...e, dtcriacao: new Date(e.dtcriacao)}))))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || err?.toString()))
        .finally(() => setLoading(false))
    }

    const updateArea = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/settings/sale/area`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setArea(data))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data || err?.toString()))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        update()
        updateArea()
        fetchBarData()
        fetchStockCompanyData()
        fetchPieData()
        fetchCostValueCompanyData()
    } ,[])
    
    // const dataBar = [
    //     { name: 'Área 1', value: 400 },
    //     { name: 'Área 2', value: 300 },
    //     { name: 'Área 3', value: 200 },
    // ];
    
    // const dataPie = [
    //     { name: 'Tipo 1', value: 45 },
    //     { name: 'Tipo 2', value: 30 },
    //     { name: 'Tipo 3', value: 25 },
    // ];
    
    // const dataStockCompany = [
    //     { empresa: 'Empresa A', estoque: 1200 },
    //     { empresa: 'Empresa B', estoque: 900 },
    //     { empresa: 'Empresa C', estoque: 600 },
    // ];
    
    // const dataCostValueCompany = [
    //     { empresa: 'Empresa A', custo: 500, valorVenda: 1500 },
    //     { empresa: 'Empresa B', custo: 400, valorVenda: 1200 },
    //     { empresa: 'Empresa C', custo: 300, valorVenda: 800 },
    // ];

    const COLORS = ['#0088FE', '#FF8042', '#00C49F'];

    const assetRef = useRef()
    const assetCompanyRef = useRef()
    const theme = useTheme()

    const tipoAtivo = Object.values(
        dataPie.reduce((acc, { idtipoativo, nometipoativo, estoque }) => {
            if (!acc[idtipoativo]) acc[idtipoativo] = { idtipoativo, nometipoativo, estoque: 0 };
            acc[idtipoativo].estoque += estoque;
            return acc;
        }, {}))

    const tipoMidia = Object.values(
        dataPie.reduce((acc, { idtipomidia, nometipomidia, estoque }) => {
            if (!acc[idtipomidia]) acc[idtipomidia] = { idtipomidia, nometipomidia, estoque: 0 };
            acc[idtipomidia].estoque += estoque;
            return acc;
        }, {})) 

    return (
        <Box 
        sx={{ 
            padding: 2, 
            overflowY:'auto',
            height:'calc(100vh - 100px)'
        }}>
            <AssetsForm 
            ref={assetRef}
            onUpdate={update}
            openCompany={data => assetCompanyRef.current.open(data)}
            onClose={() => {
                fetchBarData()
                fetchStockCompanyData()
                fetchPieData()
                fetchCostValueCompanyData()
            }}
            />

            <AssetsCompany 
            ref={assetCompanyRef}
            area={area}
            onClose={() => {
                fetchBarData()
                fetchStockCompanyData()
                fetchPieData()
                fetchCostValueCompanyData()
            }}
            />

            {/* Cabeçalho */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Gestão de Ativos</Typography>
                <Box>
                <Button 
                variant="outlined" 
                color="secondary" 
                sx={{ mr: 1, fontSize:'11px' }} 
                onClick={() => handleOpenModal('area')}>
                    Áreas de Venda
                </Button>
                <Button 
                variant="outlined" 
                color="secondary" 
                sx={{ mr: 1, fontSize:'11px' }} 
                onClick={() => handleOpenModal('asset')}>
                    Tipos de Ativo
                </Button>
                <Button 
                variant="outlined" 
                color="secondary" 
                sx={{ mr: 1, fontSize:'11px' }} 
                onClick={() => handleOpenModal('type')}>
                    Tipos de Mídia
                </Button>
                <Button 
                variant="outlined" 
                color="secondary" 
                sx={{ mr: 1, fontSize:'11px' }} 
                onClick={() => handleOpenModal('group')}>
                    Grupos de Mídia
                </Button>
                <Button 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />} 
                sx={{ fontSize:'11px' }} 
                onClick={() => assetRef.current.open()}>
                    Adicionar Ativo
                </Button>
                </Box>
            </Box>

            {modalType && (
                <CreateEntityModal 
                open={!!modalType} 
                onClose={handleCloseModal} 
                onUpdate={ action => {
                    if ( action === 'area' ) updateArea()
                }}
                type={modalType} />
            )}

            {/* Painel Resumo */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Estoque por Área de Venda
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <BarChart layout="vertical" data={dataBar}>
                                <XAxis type="number" />
                                <YAxis type="category" dataKey="nmareavenda"/>
                                <Tooltip />
                                <Bar dataKey="quantidade" fill="#8884d8">
                                    <LabelList dataKey="quantidade" fill={theme.palette.mode === 'dark' ? '#fff' : '#000'} position="inside" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Tipos de Ativo x Tipos de Mídia
                        </Typography>
                        <div
                        style={{
                            display:"flex",
                            justifyContent:'space-between',
                            alignItems:"center"
                        }}
                        >
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie 
                                    data={tipoAtivo} 
                                    dataKey="estoque" 
                                    cx="50%" 
                                    cy="50%" 
                                    outerRadius={80} 
                                    fill="#8884d8" 
                                    label={({ name, value }) => `${name}: ${value}`}
                                    nameKey="nometipoativo"
                                    >
                                        {tipoAtivo.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie 
                                    data={tipoMidia}
                                    dataKey="estoque" 
                                    cx="50%" 
                                    cy="50%" 
                                    outerRadius={80} 
                                    fill="#8884d8" 
                                    label={({ name, value }) => `${name}: ${value}`}
                                    nameKey="nometipomidia"
                                    >
                                        {tipoMidia.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Estoque x Empresa
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <BarChart data={dataStockCompany}>
                                <XAxis dataKey="nome" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="estoque" fill="#82ca9d">
                                    <LabelList dataKey="estoque" fill={theme.palette.mode === 'dark' ? '#fff' : '#000'} position="inside" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Relação de Custo x Valor de Venda Por Empresa
                        </Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <ComposedChart data={dataCostValueCompany}>
                                <XAxis dataKey="nome" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="custo" fill="#8884d8" >
                                    <LabelList dataKey="custo" fill={theme.palette.mode === 'dark' ? '#fff' : '#000'} position="inside"/>
                                </Bar>
                                <Line type="monotone" dataKey="valor" stroke="#ff7300" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>

            {/* Tabela */}
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                Lista de Ativos
                </Typography>
            </Box>

            <Table 
            list={ativos}
            header={[
                {
                    field: "idtrademktativo",
                    headerName: "ID Ativo"
                },
                {
                    field: "nome",
                    headerName: "Nome",
                    flex: 1
                },
                {
                    field: "nometipoativo",
                    headerName: "Tipo de Ativo",
                    flex: 1
                },
                {
                    field: "nometipomidia",
                    headerName: "Tipo de Mídia",
                    flex: 1
                },
                {
                    field: "nomegrupomidia",
                    headerName: "Grupo de Mídia",
                    flex: 1
                },
                {
                    field: "online",
                    headerName: "Online",
                    flex: 0.5,
                    type: 'boolean'
                },
                {
                    field: "ecommerce",
                    headerName: "E-commerce",
                    flex: 0.5,
                    type: 'boolean'
                },
                {
                    field: "controla_estoque",
                    headerName: "Controla Estoque",
                    flex: 0.5,
                    type: 'boolean'
                },
                {
                    field: "estoque_renovavel",
                    headerName: "Estoque Renovável",
                    flex: 0.5,
                    type: 'boolean'
                },
                {
                    field: "centraliza_estoque",
                    headerName: "Centraliza Estoque",
                    flex: 0.5,
                    type: 'boolean'
                },
                {
                    field: "controla_estoque_area_venda",
                    headerName: "Estoque Área Venda",
                    flex: 0.5,
                    type: 'boolean'
                },
                {
                    field: "estoque",
                    headerName: "Estoque Cadastrado",
                    flex: 0.5,
                    type: 'number'
                },
                {
                    field: "estoque_disponivel",
                    headerName: "Estoque Disponível",
                    flex: 0.5,
                    type: 'number'
                },
                {
                    field: "valor",
                    headerName: "Valor",
                    flex: 0.5,
                    renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
                    type: 'number'
                },
                {
                    field: "tipo_valor",
                    headerName: "Tipo Valor",
                    flex: 0.5,
                    renderCell: (params) => billingType[params.value]
                },
                {
                    field: "dtcriacao",
                    headerName: "Criado em",
                    type:'date',
                    flex: 1
                },
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Ações',
                    width: 100,
                    cellClassName: 'actions',
                    getActions: ({ id, row }) => {
        
                        return [
                            <GridActionsCellItem
                                icon={<VisibilityOutlinedIcon />}
                                label="Abrir"
                                className="textPrimary"
                                onClick={() => assetRef.current.open(row.idtrademktativo)}
                                color="inherit"
                            />,
                            <GridActionsCellItem
                                icon={<AddBusinessIcon />}
                                label="Adicionar Loja"
                                className="textPrimary"
                                onClick={() => assetCompanyRef.current.open(row)}
                                color="inherit"
                            />
                        ];
                    },
                }
            ]}
            rowsPerPage={5}
            pageSizeOptions={[20, 50, 100]}
            check={false}
            page={0}
            onRowClick={(data, event) => {
                if (['button', 'svg', 'path'].indexOf(event.target.localName) !== -1) return;
                // setTable({
                //     ...data.row, 
                //     open: true
                // });
            }}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        idtrademktativo: false,
                        nome: true,
                        nometipoativo: true,
                        nometipomidia: true,
                        nomegrupomidia: true,
                        online: false,
                        ecommerce: false,
                        controla_estoque: true,
                        centraliza_estoque: false,
                        controla_estoque_area_venda: false,
                        estoque_renovavel: false,
                        estoque_disponivel: false,
                        estoque: false,
                        valor: true,
                        dtcriacao: true
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 }
                }
            }}
            toolbar={true}
            sx={{
                height: '100%'
            }}
            density='compact'
            height={'500px'}
            />

        </Box>
    );
};

export default Assets;
