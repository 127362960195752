import React, { 
    useState, 
    useEffect, 
    useContext, 
    forwardRef, 
    useImperativeHandle, 
    useRef 
} from "react";

import {
  Box,
  Modal,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  IconButton,
  MenuItem,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  ListItemText,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { CompanyContext, LoadingContext, UserContext } from "../../../context/handleContext";
import { api } from "../../../utils/defaults";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close'
import Product from "../../products";
import { toast } from "react-toastify";
import { float, integer } from "../../../utils/input";

const template = {
    idmatriz: "",
    idtipoativo: null,
    idgrupomidia: null,
    idtipomidia: null,
    idproduto: null,
    nome: "",
    descricao: "",
    especificacoes: [],
    online: false,
    ecommerce: false,
    controla_estoque: false,
    estoque_renovavel: false,
    controla_estoque_area_venda: false,
    centraliza_estoque: false,
    estoque: 0,
    sobrepoe_fornecedor: false,
    limite_fornecedor: 1,
    custo_fixo: 0,
    lista_custos: [],
    tipo_custo: "action",
    valor: 0,
    tipo_valor: "action",
}

const AssetsForm = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user] = useContext(UserContext)
    const [lojas] = useContext(CompanyContext)

    const [open, setOpen] = useState(false)
    const [ativo, setAtivo] = useState(template)

    const [esp, setEsp] = useState([])
    const [cst, setCst] = useState([])

    const [tativo, settativo] = useState([])
    const [gmidia, setgmidia] = useState([])
    const [tmidia, settmidia] = useState([])

    const theme = useTheme()

    const fetchAtivo = async (id) => {
        try {
            setLoading(true)
            const response = await axios.get(`${api}/api/manage/marketing/trade/assets/${id}`,{
                headers: {
                    Authorization: user.token
                }
            })
            setAtivo(response.data);
            setCst(response.data.lista_custos || [])
            setEsp(response.data.especificacoes || [])
            setOpen( true )
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.response?.data || err?.toString())
        }
        setLoading(false)
    };

    const fetchTipoAtivo = async () => {
        try {
            const response = await axios.get(`${api}/api/manage/marketing/trade/assets/type`,{
                headers: {
                    Authorization: user.token
                }
            });
            settativo(response.data)
        } catch (error) {
            console.error("Erro ao buscar ativo:", error);
        }
    };

    const fetchGrupoMidia = async () => {
        try {
            const response = await axios.get(`${api}/api/manage/marketing/trade/group`,{
                headers: {
                    Authorization: user.token
                }
            });
            setgmidia(response.data)
        } catch (error) {
            console.error("Erro ao buscar ativo:", error);
        }
    };

    const fetchTipoMidia = async () => {
        try {
            const response = await axios.get(`${api}/api/manage/marketing/trade/type`,{
                headers: {
                    Authorization: user.token
                }
            });
            settmidia(response.data)
        } catch (error) {
            console.error("Erro ao buscar ativo:", error);
        }
    };

    const handleSaveAtivo = async () => {
        setLoading(true)
        try {
            if (ativo.idtrademktativo) {
                await axios.put(`${api}/api/manage/marketing/trade/assets/${ativo.idtrademktativo}`, {
                    ...ativo,
                    lista_custos: cst,
                    especificacoes: esp
                },{
                    headers: {
                        Authorization: user.token
                    }
                })
            } else {
                const response = await axios.post(`${api}/api/manage/marketing/trade/assets`, {
                    ...ativo,
                    lista_custos: cst,
                    especificacoes: esp
                },{
                    headers: {
                        Authorization: user.token
                    }
                })
                setAtivo({ ...ativo, idtrademktativo: response.data.idtrademktativo });
            }
            toast.success('Salvo')
            if ( typeof props.onUpdate === 'function' ) props.onUpdate()
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data || "Erro ao salvar ativo");
        }
        setLoading(false)
    };

    const handleAddEsp = () => setEsp([...esp,{ name: "", value: "" }])

    const handleRemoveEsp = (index) => {
        const updated = esp.filter((_, i) => i !== index);
        setEsp(updated);
    };

    const handleAddCst = () => setCst([...cst,{ name: "", value: 0 }])

    const handleRemoveCst = (index) => {
        const updated = cst.filter((_, i) => i !== index);
        setCst(updated);
    };

    useEffect(() => {
        if ( typeof props.onClose === 'function' && !open ) props.onClose()
        if ( !open ) {
            setAtivo(template)
            setCst([])
            setEsp([])
            return 
        }
        fetchGrupoMidia()
        fetchTipoAtivo()
        fetchTipoMidia()
    },[open])

    useImperativeHandle(ref, () => ({
        open: ( ativoId = undefined ) => {
            if ( ativoId ) fetchAtivo( ativoId )
            else setOpen( true )
        },
        close: () => setOpen(false)
    }))

    const productRef = useRef()

    return (
        <>
            <Product
            main={false}
            ref={productRef}
            />

            <Modal 
            open={open} 
            onClose={() => setOpen(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box
                sx={{
                    width: "90%",
                    maxWidth: 800,
                    margin: "auto",
                    // marginTop: "5%",
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: 3,
                    color:'text.primary',
                    maxHeight:'90vh',
                    overflowY:"auto"
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:'100%'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            alignItems:"center"
                        }}
                        >
                            <Typography variant="h6" gutterBottom>
                            {ativo.idtrademktativo ? "Editar Ativo" : "Criar Ativo"}
                            </Typography>
                            <Button
                            onClick={() => props.openCompany(ativo)}
                            variant="outlined"
                            size='small'
                            sx={{marginLeft:'10px'}}
                            startIcon={<AddBusinessIcon />}
                            disabled={!ativo.idtrademktativo}
                            >
                                Lojas
                            </Button>
                        </div>

                        <div>
                            <Button
                            color='success'
                            variant='outlined'
                            size='small'
                            sx={{marginRight:'10px'}}
                            startIcon={<SaveAltIcon />}
                            onClick={handleSaveAtivo}
                            >salvar</Button>

                            <Button
                            color='error'
                            variant='outlined'
                            size='small'
                            startIcon={<CloseIcon />}
                            onClick={() => setOpen(false)}
                            >fechar</Button>
                        </div>
                    </div>

                    {/* Formulário para dados do ativo */}
                    <TextField
                    label="Nome"
                    fullWidth
                    margin="normal"
                    value={ativo.nome}
                    size='small'
                    onChange={(e) => setAtivo({ ...ativo, nome: e.target.value })}
                    />

                    <div
                    style={{
                        display:'flex',
                        justifyContent:"space-between",
                        alignItems:'center'
                    }}
                    >
                        <FormControl 
                        fullWidth
                        size='small'
                        >
                            <InputLabel>Tipo Ativo</InputLabel>
                            <Select
                            value={ativo.idtipoativo}
                            label="Tipo Ativo"
                            onChange={(e) => setAtivo({ ...ativo, idtipoativo: e.target.value })}
                            >
                                {tativo.map(e => (<MenuItem value={e.all_category_id}>{e.name}</MenuItem>))}
                            </Select>
                        </FormControl>

                        <FormControl 
                        fullWidth
                        size='small'
                        >
                            <InputLabel>Grupo de mídia</InputLabel>
                            <Select
                            value={ativo.idgrupomidia}
                            label="Grupo de mídia"
                            onChange={(e) => setAtivo({ ...ativo, idgrupomidia: e.target.value })}
                            >
                                {gmidia.map(e => (<MenuItem value={e.all_category_id}>{e.name}</MenuItem>))}
                            </Select>
                        </FormControl>

                        <FormControl 
                        fullWidth
                        size='small'
                        >
                            <InputLabel>Tipo de mídia</InputLabel>
                            <Select
                            value={ativo.idtipomidia}
                            label="Tipo de mídia"
                            onChange={(e) => setAtivo({ ...ativo, idtipomidia: e.target.value })}
                            >
                                {tmidia.map(e => (<MenuItem value={e.all_category_id}>{e.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </div>

                    <TextField
                    label="Descrição"
                    fullWidth
                    margin="normal"
                    value={ativo.descricao}
                    multiline
                    minRows={2}
                    onChange={(e) => setAtivo({ ...ativo, descricao: e.target.value })}
                    />

                    <div
                    style={{
                        display:"flex",
                        justifyContent:'space-between'
                    }}
                    >
                        <Tooltip
                        title='Indica se ativo é proveniente de algum meio digital'
                        >
                            <FormControlLabel
                            control={
                                <Checkbox
                                checked={ativo.online}
                                onChange={(e) => setAtivo({ ...ativo, online: e.target.checked })}
                                />
                            }
                            label="Online"
                            />
                        </Tooltip>

                        <Tooltip
                        title='Indica se o ativo é proveniente de e-commerce'
                        >
                            <FormControlLabel
                            control={
                                <Checkbox
                                checked={ativo.ecommerce}
                                onChange={(e) =>
                                    setAtivo({ ...ativo, ecommerce: e.target.checked })
                                }
                                />
                            }
                            label="E-commerce"
                            />
                        </Tooltip>

                        <Tooltip title='Controla disponibilidade do ativo conforme o uso nos contratos'>
                            <FormControlLabel
                            control={
                                <Checkbox
                                checked={ativo.controla_estoque}
                                onChange={(e) => setAtivo({ 
                                    ...ativo, 
                                    controla_estoque: e.target.checked
                                })}
                                />
                            }
                            label="Controla Estoque"
                            />
                        </Tooltip>

                        <Tooltip
                        title='São ativos que possuem reposição automática de estoque quando não ocupados, sem necessidade de novas compras de ativos para repor, como equipamentos eletrônicos, outdoor etc'>
                            <FormControlLabel
                            control={
                                <Checkbox
                                checked={ativo.estoque_renovavel}
                                onChange={(e) => setAtivo({ 
                                    ...ativo, 
                                    estoque_renovavel: e.target.checked
                                })}
                                disabled={!ativo.controla_estoque}
                                />
                            }
                            label="Estoque renovável"
                            />
                        </Tooltip>

                        <Tooltip
                        title='Se ativo, gerencia o estoque separando por área de venda em cada loja. Ao inserir o ativo em uma loja será solicitado o cadastro do estoque em cada área'
                        >
                            <FormControlLabel
                            control={
                                <Checkbox
                                checked={ativo.controla_estoque_area_venda}
                                onChange={(e) => setAtivo({ 
                                    ...ativo, 
                                    controla_estoque_area_venda: e.target.checked 
                                })}
                                disabled={!ativo.controla_estoque || !ativo.estoque_renovavel}
                                />
                            }
                            label="Estoque por área"
                            />
                        </Tooltip>
                    </div>
                    
                    {ativo.controla_estoque && !ativo.estoque_renovavel ?
                    <>
                    <Typography variant="h6" sx={{ marginTop: 4 }}>
                    Associar produto
                    </Typography>
                    
                    <div
                    style={{
                        display:'flex',
                        // justifyContent:'center',
                        // alignItems:'center',
                        marginTop:'10px'
                    }}>
                        <Autocomplete
                        disablePortal
                        options={productRef.current?.list()||[]}
                        sx={{ width: '350px' }}
                        value={
                            (productRef.current?.list()||[])
                            .find(({idproduto}) => (idproduto === ativo.idproduto))
                        }
                        getOptionLabel={(option) => option.nome}
                        onChange={(event, value) => setAtivo({...ativo, idproduto: value?.idproduto})}
                        renderInput={(params) => (<TextField 
                            {...params} 
                            label="Produto" 
                            helperText={(ativo.controla_estoque && !ativo.estoque_renovavel) && !ativo.idproduto && 'Obrigatório se controla estoque não renovável'} 
                            error={(ativo.controla_estoque && !ativo.estoque_renovavel) && !ativo.idproduto}
                            />)}
                        size='small'
                        filterSelectedOptions
                        disabled={!(ativo.controla_estoque && !ativo.estoque_renovavel)}
                        renderOption={(props, option) => (
                            <ListItem
                            {...props}
                            secondaryAction={
                                <IconButton 
                                onClick={(event) => {
                                    productRef.current?.open(option.idproduto)
                                    event.preventDefault()
                                }}
                                edge="end" aria-label="delete">
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                            }
                            >
                            <ListItemText
                                primary={option.nome}
                                secondary={option.ean}
                            />
                            </ListItem>
                        )}
                        />

                        <Button
                        startIcon={<AddIcon />}
                        onClick={() => productRef.current?.create()}
                        disabled={!(ativo.controla_estoque && !ativo.estoque_renovavel)}
                        variant="outlined"
                        sx={{
                            height:'40px',
                            marginLeft:'10px'
                        }}
                        >
                            Cadastrar
                        </Button>
                    </div>
                    <Tooltip title='Centraliza na matriz e não controla estoque por loja, transforma em estoque único' >
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={ativo.centraliza_estoque}
                            onChange={(e) => setAtivo({ ...ativo, centraliza_estoque: e.target.checked })}
                            />
                        }
                        label="Centraliza Estoque"
                        />
                    </Tooltip>
                    </>:
                    <></>}

                    {ativo.controla_estoque && ativo.estoque_renovavel && !ativo.controla_estoque_area_venda ? 
                    <>
                        <Typography variant="h6" sx={{ marginTop: 4 }}>
                        Composição Estoque
                        </Typography>
                        
                        <div
                        style={{
                            display:'flex',
                            alignItems:"center",
                            marginTop:'10px'
                        }}
                        >
                            <TextField 
                            value={ativo.estoque}
                            onChange={({ target }) => setAtivo({ ...ativo, estoque: integer(target.value) })}
                            size="small"
                            sx={{width:'100px',marginRight:'10px'}}
                            label='Estoque'
                            disabled={!ativo.centraliza_estoque}
                            />

                            <Tooltip title='Centraliza na matriz e não controla estoque por loja, transforma em estoque único' >
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={ativo.centraliza_estoque}
                                    onChange={(e) => setAtivo({ ...ativo, centraliza_estoque: e.target.checked })}
                                    />
                                }
                                label="Centraliza Estoque"
                                />
                            </Tooltip>
                        </div>
                    </> : 
                    <></>}

                    <Typography variant="h6" sx={{ marginTop: 4 }}>
                    Custo e Venda
                    </Typography>

                    <div>
                        <Tooltip
                        title='Indica se o ativo pode ter mais de uma ação numa mesma janela. Alternar mídias de fornecedores diferentes no mesmo ativo dentro de um período igual. Por exemplo: Passar mídia dos fornecedores "A", "B", "C" no painel de led entre 12:00 e 13:00'
                        >
                            <FormControlLabel
                            control={
                                <Checkbox
                                checked={ativo.sobrepoe_fornecedor}
                                onChange={(e) => setAtivo({ 
                                    ...ativo, 
                                    sobrepoe_fornecedor: e.target.checked 
                                })}
                                // disabled={!ativo.estoque_renovavel}
                                />
                            }
                            label="Permite sobrepor fornecedores em uma ação"
                            />
                        </Tooltip>

                        <Tooltip title='Limite de sobreposição de fornecedores que o ativo aceitará'>
                            <TextField 
                            value={ativo.limite_fornecedor}
                            onChange={({ target }) => setAtivo({ ...ativo, limite_fornecedor: integer(target.value) })}
                            size="small"
                            sx={{width:'150px',marginRight:'10px'}}
                            label='Limite Sobreposição'
                            disabled={!ativo.sobrepoe_fornecedor}
                            />
                        </Tooltip>
                    </div>

                    <div
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                        alignItems:"center",
                        marginTop:'10px'
                    }}
                    >
                        <div
                        style={{
                            display:'flex',
                            alignItems:'center'
                        }}
                        >
                            <TextField 
                            value={ativo.custo_fixo}
                            onChange={({ target }) => setAtivo({ ...ativo, custo_fixo: float(target.value) })}
                            size="small"
                            sx={{width:'150px',marginRight:'10px'}}
                            label='Custo Simples'
                            disabled={cst?.length}
                            />

                            <Tooltip title='Periodicidade que irá contabilizar o custo'>
                                <ButtonGroup
                                size='small'
                                >
                                    <Button onClick={() => setAtivo({ ...ativo, tipo_custo: 'fixed'})} variant={ativo.tipo_custo === 'fixed' ? 'contained' : 'outlined'}>fixo</Button>
                                    <Button onClick={() => setAtivo({ ...ativo, tipo_custo: 'action'})} variant={ativo.tipo_custo === 'action' ? 'contained' : 'outlined'}>por ação</Button>
                                    <Button onClick={() => setAtivo({ ...ativo, tipo_custo: 'day'})} variant={ativo.tipo_custo === 'day' ? 'contained' : 'outlined'}>por dia</Button>
                                    <Button onClick={() => setAtivo({ ...ativo, tipo_custo: 'hour'})} variant={ativo.tipo_custo === 'hour' ? 'contained' : 'outlined'}>por hora</Button>
                                    <Button onClick={() => setAtivo({ ...ativo, tipo_custo: 'minute'})} variant={ativo.tipo_custo === 'minute' ? 'contained' : 'outlined'}>por minuto</Button>
                                </ButtonGroup>
                            </Tooltip>
                        </div>

                        <Tooltip title='Use para detalhar o custo montando uma composição'>
                            <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            onClick={handleAddCst}
                            size='small'
                            >
                            Custo Composto
                            </Button>
                        </Tooltip>
                    </div>

                    <List
                    sx={{
                        m:0,
                        p:0,
                        marginTop:'15px'
                    }}
                    >
                    {cst.map((item, index) => (
                        <ListItem 
                        sx={{
                            p:0,
                            paddingBottom:'8px'
                        }}
                        key={index}>
                            <TextField
                            label="Nome do custo"
                            value={item.name}
                            onChange={(e) => {
                                const updated = [...cst];
                                updated[index].name = e.target.value;
                                setCst(updated);
                            }}
                            sx={{ width: "50%", marginRight: 2 }}
                            size="small"
                            >
                            </TextField>
                            <TextField
                            label="Valor"
                            value={item.value}
                            onChange={(e) => {
                                const updated = [...cst];
                                updated[index].value = float(e.target.value);
                                setCst(updated);
                            }}
                            sx={{ width: "40%", marginRight: 2 }}
                            size="small"
                            />
                            <IconButton onClick={() => handleRemoveCst(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                    </List>

                    <div
                    style={{
                        display:'flex',
                        alignItems:'center',
                        marginTop:'15px'
                    }}
                    >
                        <TextField 
                        value={ativo.valor}
                        onChange={({ target }) => setAtivo({ ...ativo, valor: float(target.value) })}
                        size="small"
                        sx={{width:'150px',marginRight:'10px'}}
                        label='Valor'
                        />

                        <Tooltip
                        title='Periodicidade que irá contabilizar o valor'
                        >
                            <ButtonGroup
                            size='small'
                            >
                                <Button onClick={() => setAtivo({ ...ativo, tipo_valor: 'action'})} variant={ativo.tipo_valor === 'action' ? 'contained' : 'outlined'}>por ação</Button>
                                <Button onClick={() => setAtivo({ ...ativo, tipo_valor: 'day'})} variant={ativo.tipo_valor === 'day' ? 'contained' : 'outlined'}>por dia</Button>
                                <Button onClick={() => setAtivo({ ...ativo, tipo_valor: 'hour'})} variant={ativo.tipo_valor === 'hour' ? 'contained' : 'outlined'}>por hora</Button>
                                <Button onClick={() => setAtivo({ ...ativo, tipo_valor: 'minute'})} variant={ativo.tipo_valor === 'minute' ? 'contained' : 'outlined'}>por minuto</Button>
                            </ButtonGroup>
                        </Tooltip>
                    </div>

                    <Typography variant="h6" sx={{ marginTop: 4, marginBottom:2 }}>
                    Especificações
                    </Typography>

                    <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    onClick={handleAddEsp}
                    sx={{ marginBottom: 2 }}
                    >
                    Adicionar Especificação
                    </Button>

                    <List
                    sx={{
                        m:0,
                        p:0
                    }}
                    >
                    {esp.map((item, index) => (
                        <ListItem 
                        sx={{
                            p:0,
                            paddingBottom:'8px'
                        }}
                        key={index}>
                            <TextField
                            label="Nome"
                            value={item.name}
                            onChange={(e) => {
                                const updated = [...esp];
                                updated[index].name = e.target.value;
                                setEsp(updated);
                            }}
                            sx={{ width: "50%", marginRight: 2 }}
                            size="small"
                            >
                            </TextField>
                            <TextField
                            label="Conteúdo"
                            value={item.value}
                            onChange={(e) => {
                                const updated = [...esp];
                                updated[index].value = e.target.value;
                                setEsp(updated);
                            }}
                            sx={{ width: "40%", marginRight: 2 }}
                            size="small"
                            />
                            <IconButton onClick={() => handleRemoveEsp(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                    </List>
                </Box>
            </Modal>
        </>
    );
})

export default AssetsForm;
